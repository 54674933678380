import { Box, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export default function CustomInput({ label, ...rest }) {
  return (
    <Box>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Input size="sm" {...rest} />
    </Box>
  );
}
