import React, { useEffect } from "react";
import CustomDataModal from "./CustomDataModal";

import CustomTable from "./Table";
import {
  getAdminBookMakerCricketAPI,
  getAdminUserBookAPI,
  getAdminUserBookCricketAPI,
} from "../../service/cricket";
import { useParams } from "react-router-dom";

export default function BookMakersBook({}) {
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const { id } = useParams();
  let eventId = id;
  let gameType = "cricket";
  let type = "book";
  const getBookMakerBook = async () => {
    let data;
    if (type == "user") {
      if (gameType === "cricket") {
        const { response } = await getAdminUserBookCricketAPI(eventId);
        if (response) {
          data = response;
        }
      }
    } else if (type == "book") {
      if (gameType === "cricket") {
        const { response } = await getAdminBookMakerCricketAPI(eventId);
        if (response) {
          data = response;
        }
      }
    }
    if (data) {
      let rows_ = [];
      let columns_ = [];
      data.map((item) => {
        let row = {};
        row.userName = item?.userName; 
        item?.runnerBookResponseDTOS?.map((match) => {
          row[(match?.runnerName)] = match?.pandL;
        });
        rows_.push(row);
      });
      if (rows_.length > 0) {
        columns_.push({
          name: "User Name",
          id: "userName",
        });
        Object.keys(rows_[0]).map((key) => {
          if (key !== "userName") {
            columns_.push({ name: key, id: key });
          }
        });
        setColumns(columns_);
        setRows(rows_);
      }
    }
  };

  useEffect(() => {
    getBookMakerBook();
  }, [eventId, gameType]);
  return (
    <div className="content-wrapper">
      <CustomTable columns={columns} rows={rows} />
    </div>
  );
}
