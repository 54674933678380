import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function CustomDataModal({
  isOpen,
  children,
  header,
  onClose,
  size,
}) {
  return (
    <Modal
      scrollBehavior="inside"
      isCentered
      size={size ? size : "4xl"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxH={"90vh"}>
        <ModalHeader color="white" bg="#2B2B2B">
          {header}
        </ModalHeader>
        <ModalCloseButton onClick={onClose} color="white" />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
