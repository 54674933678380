import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getCommissionHistoryByUserAPI } from "../service/AuthService";
import { useSelector } from "react-redux";

export default function CommissionLenaDenaHistory() {
  const params = useParams();
  const userData = useSelector((state) => state.account.userData);
  const location = useLocation();
  const [payload, setPayload] = React.useState({
    startDate: new Date(moment().subtract("d", 7)).getTime(),
    endDate: new Date(moment().add("d", 1)).getTime(),
    page: 0,
    userId: params.id,
  });
  const [data, setData] = useState([]);
  const getCommissionHistory = async () => {
    const { response, code } = await getCommissionHistoryByUserAPI(payload);
    if (code == 200) {
      setData(response?.commissionHistory?.content);
    }
  };
  useEffect(() => {
    getCommissionHistory();
  }, []);
  return (
    <div className="content-wrapper">
      {" "}
      <section id="content ">
        <section className="vbox">
          <section className="scrollable padder">
            <section className="panel panel-default">
              <div
                className="ibox-title"
                style={{ background: "#004660", color: "#fff" }}
              >
                <h5>
                  Commission Len Den Of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {location?.state?.user?.clientUsername}
                  </span>
                </h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="content_bg x_panel">
                    <div
                      className="col-lg-3 col-xs-6"
                      style={{ marginBottom: 5 }}
                    >
                      <label style={{ width: "100%" }}>
                        Start Date
                        <input
                          type="date"
                          id="startDate"
                          value={moment(payload.startDate).format("YYYY-MM-DD")}
                          className="input-sm input-s form-control ng-untouched ng-pristine ng-valid"
                          style={{ width: "100%", background: "#d6e8d1" }}
                        />
                      </label>
                    </div>
                    <div
                      className="col-lg-3 col-xs-6"
                      style={{ marginBottom: 5 }}
                    >
                      <label style={{ width: "100%" }}>
                        End Date
                        <input
                          type="date"
                          value={moment(payload.endDate).format("YYYY-MM-DD")}
                          id="endDate"
                          className="input-sm input-s form-control ng-untouched ng-pristine ng-valid"
                          style={{ width: "100%", background: "#d6e8d1" }}
                        />
                      </label>
                    </div>
                    <div
                      className="col-lg-3 col-xs-6"
                      style={{ marginBottom: 5 }}
                    >
                      <label style={{ width: "100%" }}>
                        Action
                        <br />
                        <button
                          type="button"
                          className="btn btn-success"
                          style={{
                            fontWeight: "bold",
                            border: "2px solid #000",
                          }}
                          onClick={() => {
                            getCommissionHistory();
                          }}
                        >
                          Show Report
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-12 col-xs-12" style={{ padding: 0 }}>
                    <div
                      className="col-lg-12 col-xs-12"
                      style={{
                        border: "1px solid #e7eaec",
                        background:
                          "linear-gradient(to right,#2f5b69 0,#47889e 100%) !important",
                        color: "#fff",
                        padding: 7,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    />
                    <div className="table-responsive">
                      <table
                        className="table table-bordered table-striped dataTables-example dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                            >
                              Date
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                            >
                              Collection Name
                            </th>
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                            >
                              M Comm
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                            >
                              S Comm
                            </th>
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                            >
                              C Comm
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                            >
                              Done By
                            </th>
                          </tr>
                        </thead>
                        <tbody id="statements">
                          {data?.map((item) => (
                            <tr>
                              <td>
                                {moment(item.createdAt + "Z").format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td>Commission Reset</td>
                              <td className="green">
                                {item?.clientMatchCommission}
                              </td>
                              <td className="green">
                                {item?.clientSessionCommission}
                              </td>
                              <td className="green">
                                {item?.clientCasinoCommission}
                              </td>
                              <td>
                                {userData?.username + " " + userData?.name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="pull-right">
                    <br />
                    <div className=" pt-3">
                      <div className="col">
                        <div
                          className="dataTables_paginate paging_simple_numbers float-right"
                          style={{ float: "right" }}
                        >
                          <ul
                            role="menubar"
                            aria-disabled="false"
                            aria-label="Pagination"
                            className="pagination dataTables_paginate paging_simple_numbers my-0 b-pagination justify-content-end"
                            id="paginationList"
                          >
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="page-item disabled"
                            >
                              <span
                                role="menuitem"
                                aria-label="Go to first page"
                                aria-disabled="true"
                                className="page-link"
                              >
                                <i className="fa fa-caret-left" />
                                <i className="fa fa-caret-left" />
                              </span>
                            </li>
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="page-item disabled"
                            >
                              <span
                                role="menuitem"
                                aria-label="Go to previous page"
                                aria-disabled="true"
                                className="page-link"
                              >
                                <i className="fa fa-caret-left" />
                              </span>
                            </li>
                            <li
                              role="presentation"
                              className="page-item active"
                            >
                              <button
                                role="menuitemradio"
                                type="button"
                                aria-label="Go to page 1"
                                aria-checked="true"
                                aria-posinset={1}
                                aria-setsize={1}
                                tabIndex={0}
                                className="page-link"
                              >
                                1
                              </button>
                            </li>
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="page-item disabled"
                            >
                              <span
                                role="menuitem"
                                aria-label="Go to next page"
                                aria-disabled="true"
                                className="page-link"
                              >
                                <i className="fa fa-caret-right" />
                              </span>
                            </li>
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="page-item disabled"
                            >
                              <span
                                role="menuitem"
                                aria-label="Go to last page"
                                aria-disabled="true"
                                className="page-link"
                              >
                                <i className="fa fa-caret-right" />
                                <i className="fa fa-caret-right" />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>{" "}
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
