import React, { useEffect, useState } from "react";
import { allMarketsAPI } from "../service/cricket";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function MatchDashboard() {
  const params = useParams();
  const [markets, setMarkets] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const getMarkets = async () => {
    const { response } = await allMarketsAPI(params?.id, currentPage);
    setMarkets(response.content);
  };
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    getMarkets();
  }, [currentPage]);

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Matches</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="/agent/matches">Matches</a>
            </li>
            <li>
              <a href="#">
                <strong>{location?.state?.match?.eventName}</strong>
              </a>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h1>Agent Match Dashboard</h1>
              </div>
              <div className="ibox-content table-responsive">
                <p style={{ textAlign: "center" }}>
                  <a
                    onClick={() =>
                      navigate(
                        `/agent/matches/${
                          params?.id
                        }/bet_slips?isFancy=false`,
                        { state: {...location.state,isFancy:false} }
                      )
                    }
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Bet Slips
                    </button>
                  </a>

                  <a
                    onClick={() =>
                      navigate(
                        `/agent/matches/${params?.id}/bet_slips?isFancy=true`,
                        { state: {...location.state ,isFancy:true}}
                      )
                    }
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Session Bet Slip
                    </button>
                  </a>

                  <a href={`/agent/matches/${params?.id}/live_report12`}>
                    <button type="button" className="btn btn-primary btn-lg">
                      Live Report
                    </button>
                  </a>
                  <a href="/agent/matches/2096171/company_report">
                    <button type="button" className="btn btn-primary btn-lg">
                      Company Report
                    </button>
                  </a>
                  <a href="/agent/matches/2096171/client_report">
                    <button type="button" className="btn btn-primary btn-lg">
                      Client Report
                    </button>
                  </a>
                  <a href="/agent/matches/2096171/collection_report">
                    <button type="button" className="btn btn-primary btn-lg">
                      Collection Report
                    </button>
                  </a>
                  <a href="/agent/matches/1443014/session_earning_report">
                    <button type="button" className="btn btn-primary btn-lg">
                      Session Earning Report
                    </button>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Markets</h5>
                <div className="ibox-tools" />
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Betfair Market Id</th>
                      <th>Betfair Market Type</th>
                      <th>RUNNER</th>
                    </tr>
                  </thead>
                  <tbody>
                    {markets
                      ?.filter((_) => _.marketName == "BOOKMAKER_ODDS_1")
                      ?.map((item) => (
                        <tr>
                          <td>{item.id}</td>
                          <td>
                            <a href="/agent/matches/1443014/markets/1601411">
                              {item.marketId}
                            </a>
                          </td>
                          <td>{item.marketName}</td>
                          <td>2</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Sessions</h5>
                <div className="ibox-tools" />
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Betfair Market Id</th>
                      <th>Betfair Market Type</th>
                      <th>RUNNER</th>
                    </tr>
                  </thead>
                  <tbody>
                    {markets
                      ?.filter((_) => _.gameType == "Normal")
                      ?.map((item) => (
                        <tr>
                          <td>{item.id}</td>
                          <td>
                            <a href="#">{item.marketId}</a>
                          </td>
                          <td>{item.marketName}</td>
                          <td>2</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
