import React from "react";
import { useEffect } from "react";
import { generalReportAPI } from "../service/AuthService";

export default function GeneralReport() {
  const [reportType, setReportType] = React.useState("GENERAL_REPORT");
  const [generalReportData, setGeneralReportData] = React.useState([]);
  const [data, setData] = React.useState([{ leftHalf: {}, rightHalf: {} }]);
  const getGeneralReport = async () => {
    const { response, message, code } = await generalReportAPI(reportType);
    if (code == 200) {
      setGeneralReportData(response);
      let data_ = [{ leftHalf: {}, rightHalf: {} }];
      response?.generalReportAPI?.forEach((item, index) => {
        if (index % 2 == 0) {
          data_.push({
            leftHalf: item,
          });
        } else {
          data_.push({
            rightHalf: item,
          });
        }
      });
      setData(data_);
    }
  };
  useEffect(() => {
    getGeneralReport();
  }, []);
  return (
    <>

      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="mb-3">General Report</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/">Report</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="/game/inPlay">General Report</a>
                    </li>
                  </ol>
                </div>
              </div>

              <div className="col-lg-2" />
            </div>
            <div className="">
              <div className="row">
                <div className="col-md-12 main-container">
                  <div>
                    <div className="listing-grid">
                      <div className="detail-row">
                        <div id="form-search">
                          <div className="m-t-20">
                            <div className="row">
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="search-report_type">
                                    Select Type:
                                  </label>
                                  <select
                                    className="form-control"
                                    name="search-report_type"
                                    id="search-report_type"
                                    value={reportType}
                                    onChange={(e) => setReportType(e.target.value)}
                                  >
                                    <option value="GENERAL_REPORT">
                                      General Report
                                    </option>
                                    <option value="CREDIT_REFERENCE_REPORT">
                                      Balance Report
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <label style={{ width: "100%" }}>&nbsp;</label>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  id="loaddata"
                                  onClick={getGeneralReport}
                                >
                                  Load
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive data-table">
                        <table
                          id="example"
                          className="custom-table table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>Name</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody id="table_body">
                            {generalReportData?.generalReportData?.map(
                              (item, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.userName}</td>
                                  <td className="amount-field">{item.amount}</td>
                                </tr>
                              )
                            )}

                            <tr>
                              <td colSpan="100%" />
                            </tr>
                            <tr>
                              <td />
                              <td>
                                <b>
                                  Upper Level Total
                                  <b />
                                </b>
                              </td>
                              <td className="amount-field">
                                <b>{generalReportData?.upperLevelTotalAmount}</b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="100%" />
                            </tr>
                            <tr>
                              <th />
                              <th>General Total</th>
                              <th className="amount-field">
                                {generalReportData?.totalAmount}
                              </th>
                              <th />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </>
      );
}
