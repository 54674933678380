import React, { useEffect, useState } from "react";
import { accountLogAPI, getChildrenByEventId } from "../service/AuthService";
import {
  ClientWithdrawAndDepositAPI,
  getClientAPI,
} from "../service/UserService";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useQuery } from "react-query";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import toast from "react-hot-toast";

export default function DebitCreditEntry() {
  const [child, setChild] = useState([]);
  const userData = useSelector((state) => state.account.userData);
  const { userType } = useParams();
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState(
    location?.state?.userId
      ? {
          value: location?.state?.userId,
          label: location?.state?.userName,
        }
      : ""
  );

  const [accountLogs, setAccountLog] = useState([]);

  const [payload, setPayload] = React.useState({
    pageSize: 50,
    pageNumber: 0,
    search: "",
    userName: selectedUser,
  });
  const [paymentPayload, setPaymentPayload] = useState({
    id: selectedUser,
    balance: 0,
    remarks: "",
    transactionType: "WITHDRAW",
  });
  const accountLog = async (userName) => {
    try {
      const { response, code } = await accountLogAPI(
        {
          ...payload,
          userName: userName?.label.split(" ")[0],
        },
        location?.state?.isOld
      );
      if (code == 200) {
        setAccountLog(response);
      } else {
        toast.error(response);
      }
    } catch {
      console.log("error");
    }
  };

  const getChild = async (username) => {
    const { response } = await getClientAPI(
      username ? username : payload.search,
      payload.pageNumber,
      payload.pageSize,
      "",
      userType?.toLocaleUpperCase(),
      false
    );

    setChild(response.childrenUserResponseDTOSPage?.content);
    return response.childrenUserResponseDTOSPage?.content?.map((item) => ({
      value: item.uid,
      label: `${item.username} ${item.name}`,
    }));
  };
  const submitData = async () => {
    const { response, code } = await ClientWithdrawAndDepositAPI(
      selectedUser?.value,
      paymentPayload
    );
    if (code == 200) {
      accountLog(selectedUser);
      toast.success("Transaction Success");
    }
  };
  useEffect(() => {
    getChild(
      location?.state?.userName?.split(" ")[0] || location?.state?.userName
    );
    if (selectedUser) {
      accountLog(selectedUser);
    }
    setAccountLog([]);
  }, [selectedUser, payload.pageNumber]);
  useEffect(() => {
    setSelectedUser("");
    setAccountLog([]);
  }, [userType]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <app-cash-transaction
        _nghost-ng-c510633462=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c510633462="" className="page_header">
          <h1 _ngcontent-ng-c510633462="" className="page_heading">
            Cash Transaction
          </h1>
          <div _ngcontent-ng-c510633462="" className="breadcrumb_block">
            <nav _ngcontent-ng-c510633462="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c510633462="" className="breadcrumb">
                <li _ngcontent-ng-c510633462="" className="breadcrumb-item">
                  <a _ngcontent-ng-c510633462="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c510633462=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType} Debit Credit
                </li>
                <li _ngcontent-ng-c510633462="" className="breadcrumb-item">
                  Cash Transaction
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c510633462=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c510633462=""
            className="row g-3 align-items-end mb-4"
          >
            <div
              _ngcontent-ng-c510633462=""
              className="col-12 col-md-6 col-lg-3"
            >
              <div
                _ngcontent-ng-c510633462=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c510633462="">{userType}</label>
                <mat-form-field
                  _ngcontent-ng-c510633462=""
                  appearance="outline"
                  className="mat-mdc-form-field ng-tns-c1607617776-0 mat-mdc-form-field-type-mat-select mat-form-field-appearance-outline mat-primary ng-star-inserted"
                >
                  {/**/}
                  <div className="mat-mdc-text-field-wrapper mdc-text-field ng-tns-c1607617776-0 mdc-text-field--outlined mdc-text-field--no-label">
                    {/**/}
                    <div className="mat-mdc-form-field-flex ng-tns-c1607617776-0">
                      <div
                        matformfieldnotchedoutline=""
                        className="mdc-notched-outline ng-tns-c1607617776-0 mdc-notched-outline--no-label ng-star-inserted mdc-notched-outline--notched"
                      >
                        <div className="mdc-notched-outline__leading" />
                        <div
                          className="mdc-notched-outline__notch"
                          style={{ width: 0 }}
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        <div className="mdc-notched-outline__trailing" />
                      </div>
                      {/**/}
                      {/**/}
                      {/**/}
                      <div className="mat-mdc-form-field-infix ng-tns-c1607617776-0">
                        {/**/}
                        <AsyncSelect
                          loadOptions={async (inputValue) => {
                            if (inputValue.length >= 2)
                              return getChild(inputValue);
                          }}
                          cacheOptions
                          defaultOptions={child?.map((item) => ({
                            value: item?.uid,
                            label: `${item?.username} ${item?.name}`,
                          }))}
                          defaultValue={selectedUser}
                          value={selectedUser}
                          onChange={(selectedOption) => {
                            setSelectedUser(selectedOption);
                          }}
                        />
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                  <div className="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align ng-tns-c1607617776-0">
                    {/**/}
                    <div
                      className="mat-mdc-form-field-hint-wrapper ng-tns-c1607617776-0 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                      style={{ opacity: 1, transform: "translateY(0%)" }}
                    >
                      {/**/}
                      <div className="mat-mdc-form-field-hint-spacer ng-tns-c1607617776-0" />
                    </div>
                    {/**/}
                  </div>
                </mat-form-field>
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c510633462=""
              className="col-6 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c510633462="" className="input_block">
                <label _ngcontent-ng-c510633462="">Amount</label>
                <input
                  _ngcontent-ng-c510633462=""
                  type="text"
                  id="name"
                  name="name"
                  value={paymentPayload?.balance}
                  onChange={(e) =>
                    setPaymentPayload({
                      ...paymentPayload,
                      balance: e.target.value,
                    })
                  }
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c510633462=""
              className="col-6 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c510633462="" className="input_block">
                <label _ngcontent-ng-c510633462="">Payment Type</label>
                <select
                  onChange={(e) => {
                    setPaymentPayload({
                      ...paymentPayload,
                      transactionType: e.target.value,
                    });
                  }}
                  value={paymentPayload.transactionType}
                  _ngcontent-ng-c510633462=""
                  aria-label="Default select example"
                  className="form-select form-control ng-untouched ng-pristine ng-valid"
                >
                  <option
                    value={userType == "client" ? "WITHDRAW" : "DEPOSIT"}
                  >
                    PAYMENT - DENA
                  </option>
                  <option
                    value={userType == "client" ? "DEPOSIT" : "WITHDRAW"}
                  >
                    RECEIPT - LENA
                  </option>
                </select>
              </div>
            </div>
            {/* <div _ngcontent-ng-c510633462="" className="col-6 col-md-6 col-lg-3">
          <div _ngcontent-ng-c510633462="" className="input_block">
            <label _ngcontent-ng-c510633462="">Payment Mode </label>
            <select
              _ngcontent-ng-c510633462=""
              aria-label="Default select example"
              className="form-select form-control"
            >
              <option _ngcontent-ng-c510633462="" value="CASH">
                CASH
              </option>
              <option _ngcontent-ng-c510633462="" value="BANK">
                BANK
              </option>
            </select>
          </div>
        </div> */}
            <div
              _ngcontent-ng-c510633462=""
              className="col-6 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c510633462="" className="input_block">
                <label _ngcontent-ng-c510633462="">Remark</label>
                <input
                  _ngcontent-ng-c510633462=""
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    setPaymentPayload({
                      ...paymentPayload,
                      remarks: e.target.value,
                    });
                  }}
                  value={paymentPayload.remarks}
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c510633462=""
              className="col-6 col-md-6 col-lg-3"
            >
              <div
                _ngcontent-ng-c510633462=""
                className="input_block btn_block"
              >
                <button
                  _ngcontent-ng-c510633462=""
                  type="button"
                  onClick={submitData}
                  className="btn btn-primary"
                  disabled=""
                >
                  Save
                </button>
                <button
                  _ngcontent-ng-c510633462=""
                  type="button"
                  onClick={() => {
                    setSelectedUser("");
                    setAccountLog([]);
                  }}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c510633462="" className="custom_table">
            <div _ngcontent-ng-c510633462="" className="table-responsive">
              <table
                _ngcontent-ng-c510633462=""
                className="table ng-star-inserted"
              >
                <thead _ngcontent-ng-c510633462="">
                  <tr _ngcontent-ng-c510633462="">
                    <th _ngcontent-ng-c510633462="">SNo.</th>
                    <th _ngcontent-ng-c510633462="">Date</th>
                    <th _ngcontent-ng-c510633462="">Collection Name</th>
                    <th _ngcontent-ng-c510633462="">Debit</th>
                    <th _ngcontent-ng-c510633462="">Credit</th>
                    <th _ngcontent-ng-c510633462="">Balance</th>
                    <th _ngcontent-ng-c510633462="">Payment Type</th>
                    <th _ngcontent-ng-c510633462="">Payment Description</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c510633462="">
                  <tr _ngcontent-ng-c510633462="" className="tbody-color">
                    <td _ngcontent-ng-c510633462="">&nbsp;</td>
                    <td _ngcontent-ng-c510633462="">&nbsp;</td>
                    <td _ngcontent-ng-c510633462="">
                      <strong _ngcontent-ng-c510633462="">
                        Total Amount
                      </strong>
                    </td>
                    {userType == "client" ? (
                      <td className="text-gray-dark text-lg">
                        {parseFloat(
                          accountLogs?.reduce(
                            (acc, item) => acc + item.creditInfo,
                            0
                          )
                        ).toFixed(2)}
                      </td>
                    ) : (
                      <td className="text-gray-dark text-lg">
                        {parseFloat(
                          accountLogs?.reduce(
                            (acc, item) => acc + item.debitInfo,
                            0
                          )
                        ).toFixed(2)}
                      </td>
                    )}
                    {userType == "client" ? (
                      <td className="text-gray-dark text-lg">
                        {parseFloat(
                          accountLogs?.reduce(
                            (acc, item) => acc + item.debitInfo,
                            0
                          )
                        ).toFixed(2)}
                      </td>
                    ) : (
                      <td className="text-gray-dark text-lg">
                        {parseFloat(
                          accountLogs?.reduce(
                            (acc, item) => acc + item.creditInfo,
                            0
                          )
                        ).toFixed(2)}
                      </td>
                    )}
                    <td
                      className={` ${
                        accountLogs[0]?.updatedBalance > 0
                          ? "plus_value"
                          : "mainus_value"
                      } `}
                    >
                      {parseFloat(accountLogs[0]?.updatedBalance).toFixed(2)}
                    </td>
                    <td _ngcontent-ng-c510633462="">&nbsp;</td>
                  </tr>
                  {accountLogs?.length > 0 ? (
                    accountLogs
                      ?.sort(
                        (a, b) =>
                          new Date(b.createdAt) - new Date(a.createdAt)
                      )
                      ?.map((item, index) => (
                        <tr
                          _ngcontent-ng-c510633462=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c510633462="">{index}</td>
                          <td _ngcontent-ng-c510633462="">
                            <div
                              _ngcontent-ng-c510633462=""
                              className="Date_time_box"
                            >
                              {" "}
                              {moment(item.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </div>
                          </td>
                          <td _ngcontent-ng-c510633462="">C1CASH</td>
                          <td
                            _ngcontent-ng-c510633462=""
                            className={
                              userType == "client"
                                ? item.creditInfo >= 0
                                  ? "plus_value"
                                  : "mainus_value"
                                : item.debitInfo >= 0
                                ? "plus_value"
                                : "mainus_value"
                            }
                          >
                            {userType == "client"
                              ? parseFloat(item.creditInfo).toFixed(2)
                              : parseFloat(item.debitInfo).toFixed(2)}
                          </td>
                          <td
                            _ngcontent-ng-c510633462=""
                            className={
                              userType == "client"
                                ? item.creditInfo >= 0
                                  ? "plus_value"
                                  : "mainus_value"
                                : item.debitInfo >= 0
                                ? "plus_value"
                                : "mainus_value"
                            }
                          >
                            {userType == "client"
                              ? parseFloat(item.debitInfo).toFixed(2)
                              : parseFloat(item.creditInfo).toFixed(2)}
                          </td>
                          <td
                            _ngcontent-ng-c510633462=""
                            className={
                              item.updatedBalance > 0
                                ? "plus_value"
                                : "mainus_value"
                            }
                          >
                            <strong _ngcontent-ng-c510633462="">
                              {parseFloat(item.updatedBalance).toFixed(2)}
                            </strong>
                          </td>

                          {item.type == "BALANCE_REPORT" ? (
                            <td>
                              {item.debitInfo ? "LIYA HAI" : "DIYA HAI"}
                            </td>
                          ) : (
                            <td />
                          )}

                          <td _ngcontent-ng-c510633462="">{item.comment}</td>
                        </tr>
                      ))
                  ) : (
                    <tbody>
                      <tr _ngcontent-ng-c510633462="" hidden="">
                        <td
                          _ngcontent-ng-c510633462=""
                          colSpan={6}
                          style={{ textAlign: "center" }}
                        >
                          No Matches Available !
                        </td>
                      </tr>
                    </tbody>
                  )}
                </tbody>
              </table>
              {/**/}
              {/**/}
            </div>
          </div>
        </div>
      </app-cash-transaction>
      {/**/}
    </div>
  );
}
