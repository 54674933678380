import React from "react";

export default function Language() {
  return (
    <section id="main" style={{ minHeight: 481 }}>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Agent</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li className="active">
              <strong>Language</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Change Language</h5>
                <div className="ibox-tools">
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a href="#">Config option 1</a>
                    </li>
                    <li>
                      <a href="#">Config option 2</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="ibox-content table-responsive"
                style={{ padding: "15px 20px 56px" }}
              >
                <div className="dd">
                  <ol className="dd-list">
                    <li className="dd-item" data-id={1}>
                      <div
                        className="dd-handle lang-input set-language active-lang "
                        value="en"
                      >
                        English
                      </div>
                    </li>
                    <li className="dd-item" data-id={1}>
                      <div
                        className="dd-handle lang-input set-language"
                        value="hi"
                      >
                        हिंदी
                      </div>
                    </li>
                    <li className="dd-item" data-id={1}>
                      <div
                        className="dd-handle lang-input set-language"
                        value="ta"
                      >
                        தமிழ்
                      </div>
                    </li>
                    <li className="dd-item" data-id={1}>
                      <div className="dd-handle lang-input not-available">
                        मराठी
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*/ END Template Main Content */}
      <div className="footer">
        <div className="pull-right">
          Super Stockist Panel <strong>v1.2.0.0</strong>
        </div>
        <div>
          <strong>SKY7777</strong> | Powered By <strong> NICE Gaming </strong> |
          Copyright © 2014-2021{" "}
        </div>
      </div>
    </section>
  );
}
