import React, { useState } from "react";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import toast from "react-hot-toast";

const ChangePassword = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);

  const dispatch = useDispatch();

  const changePassword = async () => {
    if (password === "" || oldPassword === "" || confirmPassword === "") {
      toast.error("Please fill all the fields");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password and confirm password should be same");
      return;
    }

    if (password === oldPassword) {
      toast.error("New password should be different from old password");
      return;
    }

    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: Encrypt(password),
        password: Encrypt(oldPassword),
        resetRequired: false,
        uid: userData.id,
      },
      userData.token
    );
    if (code === 200) {
      toast.success("Password changed successfully");
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      localStorage.removeItem("userData");
      localStorage.clear();
      window.location.href = "/";
    } else {
      toast.error(message);
    }
  };
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-change-password _nghost-ng-c4140946053="">
        <div _ngcontent-ng-c4140946053="" className="page_header">
          <h1 _ngcontent-ng-c4140946053="" className="page_heading">
            Change Password
          </h1>
          <div _ngcontent-ng-c4140946053="" className="breadcrumb_block">
            <nav _ngcontent-ng-c4140946053="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c4140946053="" className="breadcrumb">
                <li _ngcontent-ng-c4140946053="" className="breadcrumb-item">
                  <a _ngcontent-ng-c4140946053="">Setting</a>
                </li>
                <li
                  _ngcontent-ng-c4140946053=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Change Password
                </li>
                <li _ngcontent-ng-c4140946053="" className="breadcrumb-item">
                  <a _ngcontent-ng-c4140946053="" href="#/dashboard">
                    Back
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c4140946053=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c4140946053=""
            noValidate=""
            className="ng-untouched ng-pristine ng-valid"
          >
            <div
              _ngcontent-ng-c4140946053=""
              className="row g-3 align-items-end mb-4"
            >
              <div
                _ngcontent-ng-c4140946053=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c4140946053="" className="input_block">
                  <label _ngcontent-ng-c4140946053="">Old Password </label>
                  <input
                    _ngcontent-ng-c4140946053=""
                    formcontrolname="oldPass"
                    type="password"
                    id="name"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    name="name"
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c4140946053=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c4140946053="" className="input_block">
                  <label _ngcontent-ng-c4140946053="">New Password </label>
                  <input
                    _ngcontent-ng-c4140946053=""
                    formcontrolname="newPass"
                    type="password"
                    id="newpaswrd"
                    name="name"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c4140946053=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c4140946053="" className="input_block">
                  <label _ngcontent-ng-c4140946053="">
                    Confirm Password{" "}
                  </label>
                  <input
                    _ngcontent-ng-c4140946053=""
                    formcontrolname="confirmPass"
                    type="password"
                    id="confirmpasswrd"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="name"
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c4140946053=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div
                  _ngcontent-ng-c4140946053=""
                  className="input_block change_pass_block"
                >
                  <button
                    _ngcontent-ng-c4140946053=""
                    type="button"
                    onClick={changePassword}
                    className="btn btn-primary"
                  >
                    Change Password
                  </button>
                  <button
                    _ngcontent-ng-c4140946053=""
                    type="button"
                    onClick={() => window.history.back()}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-change-password>
      {/**/}
    </div>
  );
};

export default ChangePassword;
