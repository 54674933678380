import React, { useEffect, useState } from "react";
import { getAllMatchesAPI } from "../service/AuthService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function InPlayList({ completeGame }) {
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const userData = useSelector((state) => state.account.userData);
  const getAllMatches = async () => {
    const { response } = await getAllMatchesAPI(currentPage, completeGame);
    setMatches(response.content);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllMatches();
  }, [currentPage, completeGame]);

  return (
    <div
      style={{
        paddingBottom: "80px",
      }}
      _ngcontent-ng-c3705319800=""
      className="main_content"
    >
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-current-sports _nghost-ng-c2354748527="">
        <div className="gx-bg-grey gx-px-5 gx-pt-3 gx-bg-flex">
          <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-pt-1 gx-text-capitalize">
            Sport Details
          </span>
          <button
            type="button"
            className="ant-btn ant-btn-primary"
            onClick={() => navigate(-1)}
          >
            <span>Back</span>
          </button>
        </div>

        <div
          _ngcontent-ng-c2354748527=""
          className="Super_master_details_section sport_details_block"
        >
          <div className="gx-bg-flex gx-py-2 gx-justify-content-center">
            <div className="ant-picker ant-picker-range  gx-border-redius0">
              <div className="ant-picker-input ant-picker-input-active">
                <input
                  readOnly=""
                  placeholder="Start date"
                  size={12}
                  autoComplete="off"
                  defaultValue=""
                />
              </div>
              <div className="ant-picker-range-separator">
                <span aria-label="to" className="ant-picker-separator">
                  <span
                    role="img"
                    aria-label="swap-right"
                    className="anticon anticon-swap-right"
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="swap-right"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z" />
                    </svg>
                  </span>
                </span>
              </div>
              <div className="ant-picker-input">
                <input
                  readOnly=""
                  placeholder="End date"
                  size={12}
                  autoComplete="off"
                  defaultValue=""
                />
              </div>
              <div
                className="ant-picker-active-bar"
                style={{ left: 0, width: 117, position: "absolute" }}
              />
              <span className="ant-picker-suffix">
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="calendar"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                  </svg>
                </span>
              </span>
            </div>
          </div>

          <div _ngcontent-ng-c2354748527="" className="custom_table">
            <div _ngcontent-ng-c2354748527="" className="table-responsive">
              <table _ngcontent-ng-c2354748527="" className="table">
                <thead _ngcontent-ng-c2354748527="">
                  <tr _ngcontent-ng-c2354748527="">
                    <th _ngcontent-ng-c2354748527="">Action</th>
                    <th _ngcontent-ng-c2354748527="">Date Time</th>
                    <th _ngcontent-ng-c2354748527="">Name</th>
                    <th _ngcontent-ng-c2354748527="">Winner</th>
                    <th _ngcontent-ng-c2354748527="">My Share P/L</th>
                    <th _ngcontent-ng-c2354748527="">Total P/L</th>
                  </tr>
                </thead>
                {matches.map((match) => (
                  <tbody _ngcontent-ng-c2354748527="">
                    <tr _ngcontent-ng-c2354748527="">
                      <td _ngcontent-ng-c2354748527="">
                        <div
                          _ngcontent-ng-c2354748527=""
                          className="dropdown action_drop"
                        >
                          <button
                            _ngcontent-ng-c2354748527=""
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="btn btn-primary dropdown-toggle action-btn-icon"
                          >
                            <i
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                              // class="fa-solid fa-gear"
                            />
                          </button>
                          <ul
                            _ngcontent-ng-c2354748527=""
                            className="dropdown-menu"
                          >
                            {/**/}
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                href={`/game/matchPosition/${match.id}`}
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-trash"
                                />{" "}
                                Match &amp; Session Position
                              </a>
                            </li>
                            {/**/}
                            <hr _ngcontent-ng-c2354748527="" />
                            {/**/}
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={
                                  "/game/sessionPlusMinusSelect/" + match.id
                                }
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-trash"
                                />{" "}
                                Match and Session Plus Minus
                              </a>
                            </li>
                            {/**/}
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={`/game/displayMatchSession/${match.id}`}
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-key"
                                />{" "}
                                Display Match &amp; Session Bets
                              </a>
                            </li>
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={`/game/i/${match?.id}/viewMatchReport`}
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-filter"
                                />{" "}
                                Display Match Bets
                              </a>
                            </li>
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={`/game/i/${
                                  match?.id
                                }/viewSessionReport`}
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-film"
                                />{" "}
                                Display Session Bets
                              </a>
                            </li>
                            <li _ngcontent-ng-c2354748527="">
                              {userData?.userType == "SUPER_ADMIN" && (
                                <a
                                  _ngcontent-ng-c2354748527=""
                                  className="dropdown-item"
                                  href={`/agent/dashboard/matches/${
                                    match?.id
                                  }/current_bets`}
                                >
                                  <i
                                    _ngcontent-ng-c2354748527=""
                                    className="icon-film"
                                  />{" "}
                                  View More Bets
                                </a>
                              )}
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td _ngcontent-ng-c2354748527="">
                        <div
                          _ngcontent-ng-c2354748527=""
                          className="Date_time_box"
                        >
                          {moment(match.eventDate).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </div>
                      </td>
                      <td _ngcontent-ng-c2354748527="">{match.eventName}</td>
                      <td _ngcontent-ng-c2354748527="">
                        <span _ngcontent-ng-c2354748527="" className="">
                          {match.wonBy}
                        </span>
                      </td>
                      <td _ngcontent-ng-c2354748527="">
                        <span
                          style={{
                            color: match?.profitLoss > 0 ? "green" : "red",
                          }}
                          _ngcontent-ng-c2354748527=""
                          className=""
                        >
                          {" "}
                          {parseFloat(match?.profitLoss).toFixed(2)}
                        </span>
                      </td>
                      <td _ngcontent-ng-c2354748527="">
                        <span
                          style={{
                            color:
                              match?.totalProfitLoss > 0 ? "green" : "red",
                          }}
                          _ngcontent-ng-c2354748527=""
                          className=""
                        >
                          {parseFloat(match?.totalProfitLoss).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}

                <tfoot _ngcontent-ng-c2354748527="">
                  <tr _ngcontent-ng-c2354748527="">
                    <td _ngcontent-ng-c2354748527="" colSpan={4}>
                      Total
                    </td>
                    <td _ngcontent-ng-c2354748527=""> 0.00 </td>
                    <td _ngcontent-ng-c2354748527="">
                      <b _ngcontent-ng-c2354748527="">
                        {" "}
                        {parseFloat(
                          matches.reduce(
                            (acc, curr) => acc + curr.profitLoss,
                            0
                          )
                        ).toFixed(2)}
                      </b>
                    </td>
                  </tr>
                </tfoot>

                <tbody>
                  {matches?.length == 0 && (
                    <tr _ngcontent-ng-c2354748527="" hidden="">
                      <td
                        _ngcontent-ng-c2354748527=""
                        colSpan={6}
                        style={{ textAlign: "center" }}
                      >
                        No Matches Available !
                      </td>
                    </tr>
                  )}
                  <tr _ngcontent-ng-c2354748527="">
                    <td _ngcontent-ng-c2354748527="" colSpan={6}>
                      <pagination-controls
                        _ngcontent-ng-c2354748527=""
                        previouslabel="Previous"
                        nextlabel="Next"
                        screenreaderpaginationlabel="Pagination"
                        screenreaderpagelabel="page"
                        screenreadercurrentlabel="You're on page"
                      >
                        <pagination-template>
                          <nav role="navigation" aria-label="Pagination">
                            <ul className="ngx-pagination">
                              <li
                                onClick={() => {
                                  if (currentPage == 0) return;
                                  setCurrentPage(currentPage - 1);
                                }}
                                className="pagination-previous "
                              >
                                {/**/}
                                <span aria-disabled="true">
                                  {" "}
                                  Previous{" "}
                                  <span className="show-for-sr">page</span>
                                </span>
                                {/**/}
                              </li>
                              {/**/}
                              <li className="small-screen"> 1 / 1 </li>
                              <li className="current">
                                {/**/}
                                <span aria-live="polite">
                                  <span className="show-for-sr">
                                    You're on page{" "}
                                  </span>
                                  <span>{currentPage + 1}</span>
                                </span>
                                {/**/}
                                {/**/}
                              </li>
                              {/**/}
                              <li
                                onClick={() => {
                                  if (matches.length == 0) return;

                                  setCurrentPage(currentPage + 1);
                                }}
                                className="pagination-next "
                              >
                                {/**/}
                                <span aria-disabled="true">
                                  {" "}
                                  Next{" "}
                                  <span className="show-for-sr">page</span>
                                </span>
                                {/**/}
                              </li>
                              {/**/}
                            </ul>
                            {/**/}
                          </nav>
                        </pagination-template>
                      </pagination-controls>
                    </td>
                    {/**/}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </app-current-sports>
    </div>
  );
}
