import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accountLogAPI, getCollectionReportAPI } from "../service/AuthService";
import { useQuery } from "react-query";
import ReactSelect from "react-select";

export default function GeneralLedger() {
  const { userType } = useParams();
  const isCollectionReport = true;
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const [isLenaDena, setIsLenaDena] = React.useState(
    location.state?.isLenaDena
  );
  const [payload, setPayload] = React.useState({
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    startDate: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD HH:mm:ss"),
    pageSize: 100,
    search: "",

    userName: location.state?.client?.username,
  });

  const navigate = useNavigate();
  const [collectionReportMapping, setCollectionReportMapping] = React.useState({
    lenaHai: [],
    denaHai: [],
  });
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [allClients, setAllClients] = React.useState([]);

  const getCollectionReport = async () => {
    const { response, code } = await getCollectionReportAPI(
      payload.startDate,
      payload.endDate,
      location.state?.isLenaDena ? "" : params?.userType,
      location.state?.isLenaDena ? params?.userType : ""
    );
    if (code === 200) {
      let data = response?.collectionReport;
      let lenaHai;
      let denaHai;
      if (selectedUser) {
        lenaHai = data?.filter(
          (item) => item?.balance > 0 && item?.userId === selectedUser?.value
        );
        denaHai = data?.filter(
          (item) => item?.balance < 0 && item?.userId === selectedUser?.value
        );
      } else {
        lenaHai = data?.filter((item) => item?.balance > 0);
        denaHai = data?.filter((item) => item?.balance < 0);
      }

      setCollectionReportMapping({
        lenaHai,
        denaHai,
      });
      const allClients = data.map((item) => ({
        value: item?.userId,
        label: item?.userName,
      }));
      setAllClients(allClients);
    }
  };
  useEffect(() => {
    if (isCollectionReport) {
      getCollectionReport();
    }
  }, [params?.userType]);
  const navigateToCt = (user) => {
    navigate(`/ct/${userType}`, {
      state: {
        ...user,
      },
    });
  };

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-agentledger _nghost-ng-c3383274413="" className="ng-star-inserted">
        {/* <div _ngcontent-ng-c3383274413="" className="page_header">
          <h1 _ngcontent-ng-c3383274413="" className="page_heading">
            {userType} Ledger{" "}
          </h1>
          <div _ngcontent-ng-c3383274413="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3383274413="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3383274413="" className="breadcrumb">
                <li _ngcontent-ng-c3383274413="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3383274413="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c3383274413="" className="breadcrumb-item">
                  <a
                    _ngcontent-ng-c3383274413=""
                    href="#/ledger/ledger-dashboard"
                  >
                    ledger
                  </a>
                </li>
                <li
                  _ngcontent-ng-c3383274413=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType} Ledger
                </li>
              </ol>
            </nav>
          </div>
        </div> */}
        <div className="gx-bg-grey gx-px-5 gx-pt-3 gx-bg-flex">
          <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-pt-1 gx-text-capitalize">
            {userType} Ledger
          </span>
          <button
            type="button"
            className="ant-btn ant-btn-primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Back</span>
          </button>
        </div>

        <div
          _ngcontent-ng-c3383274413=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              className="gx-py-1 gx-my-2 "
              style={{ borderRadius: 6, background: "rgb(16, 191, 53)" }}
            >
              <span className="gx-bg-flex gx-mx-3 gx-text-white gx-fs-xxl">
                {" "}
                <div className="gx-justify-content-start ">Lena </div>{" "}
                <div className="gx-justify-content-end">
                  {parseFloat(
                    collectionReportMapping?.lenaHai?.reduce(
                      (acc, item) => acc + item?.balance,
                      0
                    )
                  ).toFixed(2)}
                </div>
              </span>
            </div>
            <div
              className=" gx-py-1 gx-my-2 "
              style={{ borderRadius: 6, background: "rgb(222, 77, 77)" }}
            >
              <span className="gx-bg-flex gx-mx-3 gx-text-white gx-fs-xxl">
                {" "}
                <div className="gx-justify-content-start ">Dena </div>{" "}
                <div className="gx-justify-content-end">
                  {parseFloat(
                    collectionReportMapping?.denaHai?.reduce(
                      (acc, item) => acc + item?.balance,
                      0
                    )
                  ).toFixed(2)}
                </div>
              </span>
            </div>
            <div
              className="gx-py-1 gx-my-2 "
              style={{ borderRadius: 6, background: "rgb(92, 181, 245)" }}
            >
              <span className="gx-bg-flex gx-mx-3 gx-text-white gx-fs-xxl">
                {" "}
                <div className="gx-justify-content-start ">Clear </div>{" "}
                <div className="gx-justify-content-end"> {0}</div>
              </span>
            </div>
          </div>

          <div _ngcontent-ng-c3383274413="" className="row g-3">
            <div
              _ngcontent-ng-c3383274413=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div
                _ngcontent-ng-c3383274413=""
                className="custom_table lena_table"
              >
                <div
                  _ngcontent-ng-c3383274413=""
                  className="table-responsive"
                >
                  <table _ngcontent-ng-c3383274413="" className="table">
                    <thead _ngcontent-ng-c3383274413="">
                      <tr _ngcontent-ng-c3383274413="">
                        <th _ngcontent-ng-c3383274413="">Agent Name</th>

                        <th _ngcontent-ng-c3383274413="">Cls. Bal.</th>
                        <th _ngcontent-ng-c3383274413="">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="25"
                            width="25"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M327.027 65.816L229.79 128.23l9.856 5.397 86.51-55.53 146.735 83.116-84.165 54.023 4.1 2.244v6.848l65.923-42.316 13.836 7.838-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l100.033-64.21-24.828-14.062 24.827-15.937-24.828-14.064 24.827-15.937-23.537-13.333 23.842-15.305-166.135-94.106zm31.067 44.74c-21.038 10.556-49.06 12.342-68.79 4.383l-38.57 24.757 126.903 69.47 36.582-23.48c-14.41-11.376-13.21-28.35 2.942-41.67l-59.068-33.46zM227.504 147.5l-70.688 46.094 135.61 78.066 1.33-.85c2.5-1.61 6.03-3.89 10.242-6.613 8.42-5.443 19.563-12.66 30.674-19.86 16.002-10.37 24.248-15.72 31.916-20.694L227.504 147.5zm115.467 1.17a8.583 14.437 82.068 0 1 .003 0 8.583 14.437 82.068 0 1 8.32 1.945 8.583 14.437 82.068 0 1-.87 12.282 8.583 14.437 82.068 0 1-20.273 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.237zm-218.423 47.115L19.143 263.44l23.537 13.333-23.842 15.305 24.828 14.063-24.828 15.938 24.828 14.063-24.828 15.938 166.135 94.106L285.277 381.8V370.08l-99.433 63.824L39.11 350.787l14.255-9.15 131.608 74.547L285.277 351.8V340.08l-99.433 63.824L39.11 320.787l14.255-9.15 131.608 74.547L285.277 321.8V310.08l-99.433 63.824L39.11 290.787l13.27-8.52 132.9 75.28 99.997-64.188v-5.05l-5.48-3.154-93.65 60.11-146.73-83.116 94.76-60.824-9.63-5.543zm20.46 11.78l-46.92 30.115c14.41 11.374 13.21 28.348-2.942 41.67l59.068 33.46c21.037-10.557 49.057-12.342 68.787-4.384l45.965-29.504-123.96-71.358zm229.817 32.19c-8.044 5.217-15.138 9.822-30.363 19.688-11.112 7.203-22.258 14.42-30.69 19.873-4.217 2.725-7.755 5.01-10.278 6.632-.09.06-.127.08-.215.137v85.924l71.547-48.088v-84.166zm-200.99 17.48a8.583 14.437 82.068 0 1 8.32 1.947 8.583 14.437 82.068 0 1-.87 12.28 8.583 14.437 82.068 0 1-20.27 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.236z" />
                          </svg>
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      _ngcontent-ng-c3383274413=""
                      className="ng-star-inserted"
                    >
                      <tr
                        _ngcontent-ng-c3383274413=""
                        className="bd-blue-100"
                      >
                        <td _ngcontent-ng-c3383274413="" colSpan={4}>
                          <b _ngcontent-ng-c3383274413="">
                            PAYMENT RECEIVING FROM (LENA HE)
                          </b>
                        </td>
                      </tr>
                      {collectionReportMapping?.lenaHai?.map((item) => (
                        <tr
                          _ngcontent-ng-c3383274413=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c3383274413="">
                            {item?.userName}{" "}
                            <br _ngcontent-ng-c3383274413="" />
                          </td>

                          <td _ngcontent-ng-c3383274413="">
                            <span
                              _ngcontent-ng-c3383274413=""
                              className={
                                item?.balance < 0
                                  ? "mainus_value"
                                  : "plus_value"
                              }
                            >
                              <strong _ngcontent-ng-c3383274413="">
                                {parseFloat(item?.balance).toFixed(2)}
                              </strong>
                            </span>
                          </td>
                          <td onClick={() => navigateToCt(item)}>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 512 512"
                              height="25"
                              width="25"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M327.027 65.816L229.79 128.23l9.856 5.397 86.51-55.53 146.735 83.116-84.165 54.023 4.1 2.244v6.848l65.923-42.316 13.836 7.838-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l100.033-64.21-24.828-14.062 24.827-15.937-24.828-14.064 24.827-15.937-23.537-13.333 23.842-15.305-166.135-94.106zm31.067 44.74c-21.038 10.556-49.06 12.342-68.79 4.383l-38.57 24.757 126.903 69.47 36.582-23.48c-14.41-11.376-13.21-28.35 2.942-41.67l-59.068-33.46zM227.504 147.5l-70.688 46.094 135.61 78.066 1.33-.85c2.5-1.61 6.03-3.89 10.242-6.613 8.42-5.443 19.563-12.66 30.674-19.86 16.002-10.37 24.248-15.72 31.916-20.694L227.504 147.5zm115.467 1.17a8.583 14.437 82.068 0 1 .003 0 8.583 14.437 82.068 0 1 8.32 1.945 8.583 14.437 82.068 0 1-.87 12.282 8.583 14.437 82.068 0 1-20.273 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.237zm-218.423 47.115L19.143 263.44l23.537 13.333-23.842 15.305 24.828 14.063-24.828 15.938 24.828 14.063-24.828 15.938 166.135 94.106L285.277 381.8V370.08l-99.433 63.824L39.11 350.787l14.255-9.15 131.608 74.547L285.277 351.8V340.08l-99.433 63.824L39.11 320.787l14.255-9.15 131.608 74.547L285.277 321.8V310.08l-99.433 63.824L39.11 290.787l13.27-8.52 132.9 75.28 99.997-64.188v-5.05l-5.48-3.154-93.65 60.11-146.73-83.116 94.76-60.824-9.63-5.543zm20.46 11.78l-46.92 30.115c14.41 11.374 13.21 28.348-2.942 41.67l59.068 33.46c21.037-10.557 49.057-12.342 68.787-4.384l45.965-29.504-123.96-71.358zm229.817 32.19c-8.044 5.217-15.138 9.822-30.363 19.688-11.112 7.203-22.258 14.42-30.69 19.873-4.217 2.725-7.755 5.01-10.278 6.632-.09.06-.127.08-.215.137v85.924l71.547-48.088v-84.166zm-200.99 17.48a8.583 14.437 82.068 0 1 8.32 1.947 8.583 14.437 82.068 0 1-.87 12.28 8.583 14.437 82.068 0 1-20.27 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.236z" />
                            </svg>
                          </td>
                        </tr>
                      ))}
                      {/**/}
                      {/**/}
                    </tbody>
                    {/**/}
                    <tfoot
                      _ngcontent-ng-c3383274413=""
                      className="ng-star-inserted"
                    >
                      <tr _ngcontent-ng-c3383274413="">
                        <td _ngcontent-ng-c3383274413="" colSpan={1}>
                          Total
                        </td>

                        <td _ngcontent-ng-c3383274413="">
                          <span
                            _ngcontent-ng-c3383274413=""
                            className={
                              collectionReportMapping?.lenaHai?.reduce(
                                (acc, item) => acc + item?.balance,
                                0
                              ) < 0
                                ? "mainus_value"
                                : "plus_value"
                            }
                          >
                            <strong _ngcontent-ng-c3383274413="">
                              {parseFloat(
                                collectionReportMapping?.lenaHai?.reduce(
                                  (acc, item) => acc + item?.balance,
                                  0
                                )
                              ).toFixed(2)}
                            </strong>
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                    {/**/}
                    {/**/}
                  </table>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c3383274413=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div
                _ngcontent-ng-c3383274413=""
                className="custom_table dena_table"
              >
                <div
                  _ngcontent-ng-c3383274413=""
                  className="table-responsive"
                >
                  <table _ngcontent-ng-c3383274413="" className="table">
                    <thead _ngcontent-ng-c3383274413="">
                      <tr _ngcontent-ng-c3383274413="">
                        <th _ngcontent-ng-c3383274413="">Agent Name</th>

                        <th _ngcontent-ng-c3383274413="">Cls. Bal.</th>
                        <th>
                          <span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 512 512"
                              height="25"
                              width="25"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M327.027 65.816L229.79 128.23l9.856 5.397 86.51-55.53 146.735 83.116-84.165 54.023 4.1 2.244v6.848l65.923-42.316 13.836 7.838-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l100.033-64.21-24.828-14.062 24.827-15.937-24.828-14.064 24.827-15.937-23.537-13.333 23.842-15.305-166.135-94.106zm31.067 44.74c-21.038 10.556-49.06 12.342-68.79 4.383l-38.57 24.757 126.903 69.47 36.582-23.48c-14.41-11.376-13.21-28.35 2.942-41.67l-59.068-33.46zM227.504 147.5l-70.688 46.094 135.61 78.066 1.33-.85c2.5-1.61 6.03-3.89 10.242-6.613 8.42-5.443 19.563-12.66 30.674-19.86 16.002-10.37 24.248-15.72 31.916-20.694L227.504 147.5zm115.467 1.17a8.583 14.437 82.068 0 1 .003 0 8.583 14.437 82.068 0 1 8.32 1.945 8.583 14.437 82.068 0 1-.87 12.282 8.583 14.437 82.068 0 1-20.273 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.237zm-218.423 47.115L19.143 263.44l23.537 13.333-23.842 15.305 24.828 14.063-24.828 15.938 24.828 14.063-24.828 15.938 166.135 94.106L285.277 381.8V370.08l-99.433 63.824L39.11 350.787l14.255-9.15 131.608 74.547L285.277 351.8V340.08l-99.433 63.824L39.11 320.787l14.255-9.15 131.608 74.547L285.277 321.8V310.08l-99.433 63.824L39.11 290.787l13.27-8.52 132.9 75.28 99.997-64.188v-5.05l-5.48-3.154-93.65 60.11-146.73-83.116 94.76-60.824-9.63-5.543zm20.46 11.78l-46.92 30.115c14.41 11.374 13.21 28.348-2.942 41.67l59.068 33.46c21.037-10.557 49.057-12.342 68.787-4.384l45.965-29.504-123.96-71.358zm229.817 32.19c-8.044 5.217-15.138 9.822-30.363 19.688-11.112 7.203-22.258 14.42-30.69 19.873-4.217 2.725-7.755 5.01-10.278 6.632-.09.06-.127.08-.215.137v85.924l71.547-48.088v-84.166zm-200.99 17.48a8.583 14.437 82.068 0 1 8.32 1.947 8.583 14.437 82.068 0 1-.87 12.28 8.583 14.437 82.068 0 1-20.27 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.236z" />
                            </svg>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c3383274413="">
                      <tr
                        _ngcontent-ng-c3383274413=""
                        className="bd-blue-100"
                      >
                        <td _ngcontent-ng-c3383274413="" colSpan={4}>
                          <b _ngcontent-ng-c3383274413="">
                            PAYMENT RECEIVING FROM (DENA HE)
                          </b>
                        </td>
                      </tr>
                      {collectionReportMapping?.denaHai?.length > 0 ? (
                        collectionReportMapping?.denaHai?.map((item) => (
                          <tr
                            _ngcontent-ng-c3383274413=""
                            className="ng-star-inserted"
                          >
                            <td _ngcontent-ng-c3383274413="">
                              {item?.userName}{" "}
                              <br _ngcontent-ng-c3383274413="" />
                            </td>

                            <td _ngcontent-ng-c3383274413="">
                              <span
                                _ngcontent-ng-c3383274413=""
                                className={
                                  item?.balance < 0
                                    ? "mainus_value"
                                    : "plus_value"
                                }
                              >
                                <strong _ngcontent-ng-c3383274413="">
                                  {parseFloat(item?.balance).toFixed(2)}
                                </strong>
                              </span>
                            </td>
                            <td>
                              <span onClick={() => navigateToCt(item)}>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 512 512"
                                  height="25"
                                  width="25"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M327.027 65.816L229.79 128.23l9.856 5.397 86.51-55.53 146.735 83.116-84.165 54.023 4.1 2.244v6.848l65.923-42.316 13.836 7.838-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l64.633-41.487 15.127 8.57-79.76 51.195v11.723l100.033-64.21-24.828-14.062 24.827-15.937-24.828-14.064 24.827-15.937-23.537-13.333 23.842-15.305-166.135-94.106zm31.067 44.74c-21.038 10.556-49.06 12.342-68.79 4.383l-38.57 24.757 126.903 69.47 36.582-23.48c-14.41-11.376-13.21-28.35 2.942-41.67l-59.068-33.46zM227.504 147.5l-70.688 46.094 135.61 78.066 1.33-.85c2.5-1.61 6.03-3.89 10.242-6.613 8.42-5.443 19.563-12.66 30.674-19.86 16.002-10.37 24.248-15.72 31.916-20.694L227.504 147.5zm115.467 1.17a8.583 14.437 82.068 0 1 .003 0 8.583 14.437 82.068 0 1 8.32 1.945 8.583 14.437 82.068 0 1-.87 12.282 8.583 14.437 82.068 0 1-20.273 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.237zm-218.423 47.115L19.143 263.44l23.537 13.333-23.842 15.305 24.828 14.063-24.828 15.938 24.828 14.063-24.828 15.938 166.135 94.106L285.277 381.8V370.08l-99.433 63.824L39.11 350.787l14.255-9.15 131.608 74.547L285.277 351.8V340.08l-99.433 63.824L39.11 320.787l14.255-9.15 131.608 74.547L285.277 321.8V310.08l-99.433 63.824L39.11 290.787l13.27-8.52 132.9 75.28 99.997-64.188v-5.05l-5.48-3.154-93.65 60.11-146.73-83.116 94.76-60.824-9.63-5.543zm20.46 11.78l-46.92 30.115c14.41 11.374 13.21 28.348-2.942 41.67l59.068 33.46c21.037-10.557 49.057-12.342 68.787-4.384l45.965-29.504-123.96-71.358zm229.817 32.19c-8.044 5.217-15.138 9.822-30.363 19.688-11.112 7.203-22.258 14.42-30.69 19.873-4.217 2.725-7.755 5.01-10.278 6.632-.09.06-.127.08-.215.137v85.924l71.547-48.088v-84.166zm-200.99 17.48a8.583 14.437 82.068 0 1 8.32 1.947 8.583 14.437 82.068 0 1-.87 12.28 8.583 14.437 82.068 0 1-20.27 1.29 8.583 14.437 82.068 0 1 .87-12.28 8.583 14.437 82.068 0 1 11.95-3.236z" />
                                </svg>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          _ngcontent-ng-c3383274413=""
                          className="ng-star-inserted"
                        >
                          <td
                            _ngcontent-ng-c3383274413=""
                            colSpan={4}
                            style={{ textAlign: "center" }}
                          >
                            No Record Found!
                          </td>
                        </tr>
                      )}

                      {/**/}
                      {/**/}
                    </tbody>
                    {/**/}
                    <tfoot
                      _ngcontent-ng-c3383274413=""
                      className="ng-star-inserted"
                    >
                      <tr _ngcontent-ng-c3383274413="">
                        <td _ngcontent-ng-c3383274413="" colSpan={1}>
                          Total
                        </td>

                        <td _ngcontent-ng-c3383274413="">
                          <span
                            _ngcontent-ng-c3383274413=""
                            className={
                              collectionReportMapping?.denaHai?.reduce(
                                (acc, item) => acc + item?.balance,
                                0
                              ) < 0
                                ? "mainus_value"
                                : "plus_value"
                            }
                          >
                            <strong _ngcontent-ng-c3383274413="">
                              {parseFloat(
                                collectionReportMapping?.denaHai?.reduce(
                                  (acc, item) => acc + item?.balance,
                                  0
                                )
                              ).toFixed(2)}
                            </strong>
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-agentledger>
      {/**/}
    </div>
  );
}
