import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCoinHistoryAPI } from "../service/AuthService";
import moment from "moment";

export default function ClientChangelog() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [coinReport, setCoinReport] = React.useState([]);
  const getChangelog = async () => {
    const { response, code } = await getCoinHistoryAPI(params?.id);
    if (code === 200) {
      setCoinReport(response.coinHistory);
    }
  };

  useEffect(() => {
    getChangelog();
  }, []);
  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>User</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/admin/dashboard">Dashboard</a>
            </li>
            <li>
              <a
                href={`/agent/users?users=${
                  location.state?.client?.userType
                }`}
              >
                {location.state?.client?.userType}
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  navigate(`/agent/users/${params?.id}`, {
                    state: {
                      client: location.state?.client,
                    },
                  })
                }
              >
                {location?.state?.client?.username}
              </a>
            </li>
            <li className="active">
              <strong>Coin History</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>
                  {location?.state?.client?.username} Current User Changelog
                  Details
                </h5>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Changelog ID</th>
                      <th>Description</th>
                      <th>Coins</th>
                      <th>Fix Limit</th>
                      <th>Match Commission</th>
                      <th>Session Commission</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coinReport?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.description}</td>
                          <td>{item.updatedBalance}</td>
                          <td>{item.fixedLimit}</td>
                          <td>{location?.state?.client?.matchCommission}</td>
                          <td>
                            {location?.state?.client?.sessionCommission}
                          </td>
                          <td>
                            {moment(item.createdAt).format(
                              "DD MM YYYY HH:mm"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
