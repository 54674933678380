import React, { useEffect } from "react";
import AsyncSelect from "react-select/async";
import {
  getCasinoClientBets,
  searchUserAPI,
  updateClientsCasinoBetsAPI,
} from "../../service/AuthService";
import { CASINOS } from "../../utils/constants";
import moment from "moment";
import toast from "react-hot-toast";
export default function CasinoBetReport() {
  const [payload, setPayload] = React.useState({
    userName: "c5",
    startDate: moment().subtract(5, "days"),
    endDate: moment(),
    casinoGames: CASINOS[0],
  });
  const [child, setChild] = React.useState([]);
  const [data, setData] = React.useState([]);
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      setChild(
        response.response?.map((userName) => ({
          value: userName,
          label: userName,
        })) || []
      );
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  const handleSubmit = async () => {
    const { response, code } = await getCasinoClientBets({
      ...payload,
      userName: payload?.userName?.value?.split(" ")[0],
    });
    if (code === 200) {
      setData(response);
    }
  };
  useEffect(() => {
    searchClient(payload?.userName);
  }, []);
  const handleDelete = async (item) => {
    const { response, code } = await updateClientsCasinoBetsAPI(
      item?.id,
      "DELETED"
    );
    if (code === 200) {
      toast.success("Deleted Successfully");
    } else {
      toast.error(response);
    }
  };
  return (
    <div className="content-wrapper" style={{ minHeight: "1300.41px" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6" />
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Casino Bet Reports</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div action="/casino/report" method="post">
                  <input
                    type="hidden"
                    name="_csrf"
                    defaultValue="5b20d1b0-ea98-4758-9544-d486efb9e33b"
                  />
                  <div className="card-header bg-gradient-purple">
                    <h4>Casino Bet Report</h4>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="name">Client</label>
                        <AsyncSelect
                          cacheOptions
                          className=""
                          defaultOptions={child}
                          loadOptions={searchClient}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              userName: e,
                            })
                          }
                          isClearable={true}
                          value={payload?.userName}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "35px",
                              minHeight: "35px",
                              color: "black !important",
                            }),
                            container: (base) => ({
                              ...base,
                              color: "black !important",
                            }),
                          }}
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="type">Game Type</label>
                        <select
                          className="form-control custom-select"
                          required=""
                          id="type"
                          placeholder="Select Client"
                          name="gameType"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              casinoGames: e.target.value,
                            })
                          }
                        >
                          {CASINOS.map((casino) => (
                            <option key={casino} value={casino}>
                              {casino}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group">
                          <label htmlFor="from">Date From</label>
                          <div
                            className="input-group date"
                            id="reservationdateFrom"
                            data-target-input="nearest"
                          >
                            <input
                              type="date"
                              className="form-control datetimepicker-input"
                              id="from"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  startDate: e.target.value,
                                })
                              }
                              data-target="#reservationdateFrom"
                              name="dateFrom"
                              value={
                                payload?.startDate
                                  ? moment(payload?.startDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : ""
                              }
                            />
                            <div
                              className="input-group-append"
                              data-target="#reservationdateFrom"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group">
                          <label htmlFor="to">Date To</label>
                          <div
                            className="input-group date"
                            id="reservationdateTo"
                            data-target-input="nearest"
                          >
                            <input
                              type="date"
                              className="form-control datetimepicker-input"
                              id="to"
                              value={
                                payload?.endDate
                                  ? moment(payload?.endDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : ""
                              }
                              data-target="#reservationdateTo"
                              name="dateTo"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  endDate: e.target.value,
                                })
                              }
                            />
                            <div
                              className="input-group-append"
                              data-target="#reservationdateTo"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <label
                          className="control-label text-purple"
                          htmlFor="btn"
                        >
                          `
                        </label>
                        <input
                          type="submit"
                          className="form-control btn-primary"
                          id="btn"
                          name="submit"
                          onClick={handleSubmit}
                          defaultValue="Sumbit"
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped dataTable no-footer"
                    >
                      <thead className="bg-gradient-yellow">
                        <tr>
                          <th>#</th>
                          <th>Client</th>
                          <th>EventId</th>
                          <th>Market</th>
                          <th>Rate</th>
                          <th>Amount</th>
                          <th>Profit/Loss</th>
                          <th>Won</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th
                            colSpan={5}
                            className="text-indigo text-lg text-center"
                          >
                            Total Amount
                          </th>
                          <th className="text-gray-dark text-lg">
                            {data?.reduce(
                              (acc, item) => acc + item?.amount,
                              0
                            )}
                          </th>
                          <th className="text-blue text-lg">
                            {data?.reduce(
                              (acc, item) => acc + item?.profitLoss,
                              0
                            )}
                          </th>
                          <th />
                          <th />
                        </tr>
                        {data?.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.marketId}</td>
                            <td>{item?.runnerName}</td>
                            <td>{item?.backRate}</td>
                            <td>{item?.amount}</td>
                            <td
                              className={
                                item?.profitLoss < 0
                                  ? "text-red"
                                  : "text-green"
                              }
                            >
                              {item?.profitLoss}
                            </td>
                            <td>{item?.wonBy}</td>
                            <td>
                              {moment(item?.createdAt + "Z").format(
                                "DD MM YYYY hh:mm:ss A"
                              )}
                            </td>
                            <td>
                              <a
                                className="btn btn-danger"
                                onClick={() => handleDelete(item)}
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot />
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
