import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  betAndFancyLocksCricketAPI,
  getAdminPlExposureCricketAPI,
  getBetActiveDeactiveCricketAPI,
  getClientBetsCricketAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getSettledFancyAPI,
} from "../service/cricket";
import {
  getLiveStreamingUrlAPI,
  getSportsScoreAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import BookMakersBook from "./components/BookMakerBook";
import ViewMoreBet from "./components/ViewMoreBet";
import { useQuery } from "react-query";

export default function CricketMatch() {
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const gameOdds = useSelector((state) => state.gameData);
  const gameDetails = useSelector((state) => state.gameData);
  const [isBooksModalOpen, setIsBookModalOpen] = useState(false);
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [settledFancy, setSettledFancy] = useState({});
  const [matchData, setMatchData] = useState([]);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [isLiveTvOpen, setIsLiveTvOpen] = useState(false);
  const [gameSettings_, setGameSettings_] = useState({});
  const [everyRunnerExpousure, setEveryRunnerExpousure] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [positionModal, setPositionModal] = useState(null);
  const [isBookMakerOpen, setIsBookMakerOpen] = useState(false);
  const [isViewMoreBetModalOpen, setIsViewMoreBetModalOpen] = useState(false);
  const [isFancy, setIsFancy] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  var gameType = "cricket";
  var eventId = params?.id;
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const scoreData = useQuery("scoreData", () => getSportsScoreAPI(eventId), {
    refetchInterval: 4000,
  });
  const getGameEventDetails = useCallback(async () => {
    const { response } = await getCricketEventDetailsAPI(eventId);

    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  const getSettledFancy = async () => {
    const { response } = await getSettledFancyAPI(eventId);
    setSettledFancy(response);
  };
  useEffect(() => {
    getGameEventDetails();
    setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);
  const getCricketPlExposure = async () => {
    const { response } = await getAdminPlExposureCricketAPI(eventId);
    if (response) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      isFancy,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveCricketAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(eventId);
    if (code != 200) {
      toast.error("Something went wrong");
      window.location.href = "/";
      return;
    }
    let response_ = {};
    response_[eventId] = response;
    setMatchOdds(response);
    dispatch({
      type: "gameData/setCricketOdds",
      payload: response_,
    });
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      getCricketPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getCricketPlExposure();
        getClientBets();
        getBetActiveDeactive();
        getSettledFancy();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
    }, 3000);
    return () => clearInterval(interval);
  }, [eventId]);

  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);

  return (
    <>
      {isBookMakerOpen && (
        <BookMakersBook
          type={"book"}
          eventId={eventId}
          isOpen={isBookMakerOpen}
          onClose={() => setIsBookMakerOpen(false)}
          gameType={gameType}
        />
      )}
      {isViewMoreBetModalOpen && (
        <ViewMoreBet
          eventId={eventId}
          gameType={gameType}
          isFancy={isFancy}
          setIsFancy={setIsFancy}
          onClose={() => setIsViewMoreBetModalOpen(false)}
          isOpen={isViewMoreBetModalOpen}
          data={clientBets}
          getClientBets={getClientBets}
        />
      )}
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>LIVE MATCH REPORT</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="/agent/matches/">Matches</a>
            </li>
            <li>
              <a>{matchDetails?.eventName}</a>
            </li>
            <li>
              <a href="#">
                <strong>
                  <strong>Live Report</strong>
                </strong>
              </a>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div>
        <div
          className="dragOuter-toggler jello-horizontal showToggler"
          id="tvDiv"
          onclick='showTV( "HTTPS://100TUN.ONLINE/WEB/33032529.html", "33032529")'
          data-id={33032529}
        >
          <svg
            version={1.0}
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="512.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M1535 5096 c-27 -13 -62 -36 -77 -52 -92 -98 -72 -269 40 -347 41
    -28 57 -32 123 -35 l76 -4 234 -234 234 -234 -865 0 c-713 0 -877 -3 -933 -15
    -182 -38 -330 -193 -358 -377 -6 -39 -8 -603 -7 -1513 3 -1311 5 -1454 20
    -1495 53 -148 156 -249 303 -300 53 -18 84 -20 331 -20 l272 0 4 -197 c3 -218
    6 -230 66 -259 46 -22 98 -11 134 28 26 28 28 36 28 109 l0 79 1400 0 1400 0
    0 -79 c0 -73 2 -81 28 -109 36 -39 88 -50 134 -28 60 29 63 41 66 259 l4 197
    272 0 c247 0 278 2 331 20 147 51 250 152 303 300 15 41 17 184 20 1495 1 910
    -1 1474 -7 1513 -21 135 -117 270 -238 335 -105 56 -110 57 -1053 57 l-865 0
    234 234 234 234 76 4 c66 3 82 7 123 35 112 78 132 249 40 347 -38 40 -118 76
    -172 76 -52 0 -134 -35 -167 -71 -38 -42 -65 -115 -62 -170 l3 -50 -320 -320
    -319 -319 -65 0 -65 0 -319 319 -320 320 3 50 c3 55 -24 128 -62 170 -33 36
    -115 71 -167 71 -27 0 -66 -10 -95 -24z m3210 -1159 c51 -23 91 -61 118 -112
    l22 -40 3 -1425 c2 -975 -1 -1441 -8 -1476 -15 -69 -59 -127 -122 -158 l-52
    -26 -2146 0 -2146 0 -52 26 c-63 31 -107 89 -122 158 -7 35 -10 501 -8 1476
    l3 1425 22 41 c37 69 91 107 178 127 11 3 976 4 2145 3 2024 -1 2127 -2 2165
    -19z"
              />
            </g>
          </svg>
        </div>
        <div
          className="dragOuter"
          id="dragOuter"
          style={{ position: "fixed" }}
        >
          <div className="dragHeader">
            <div className="closeDrag" onclick="closeTV('33032529')">
              <img className="" src="/images/close_icon.png" alt="" />
            </div>
          </div>
          <div className="TVwrapper">
            <iframe
              id="tvIFrame"
              src=""
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />
          </div>
        </div>
        {/* TV Code End */}
      </div>
      <div id="render-live-report12">
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n   @media (max-width: 768px){\n   .header-top-spacing .row {\n   margin: 15px 15px !important;\n   }\n   }\n   p.match-time-dtl-date.live-report-match-time,\n   p.match-time-dtl-mounth.live-report-match-month,\n   p.match-time-dtl-mounth.live-report-match-date{\n   margin: 0;\n   }\n   .in-play-row-left.live-report-in-play-row-left{\n   height: 80px;\n   }\n   .align-left{\n     text-align: left;\n   }\n",
          }}
        />
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="row">
                  <div
                    className="ldg-tbl-col col-lg-9 col-xs-8 col-md-9 col-sm-8 score-event-box match-status-table"
                    style={{ paddingLeft: 5, paddingRight: "3px !important" }}
                  >
                    <p
                      className="inplay-show-tbl-td match-status-text"
                      style={{
                        backgroundColor: "#3A61A2",
                        textAlign: "center",
                        fontSize: 15,
                      }}
                      id="score_table_1436644"
                    >
                      {scoreData?.data?.response?.data?.data?.teams?.map(
                        (item) => (
                          <span>
                            {item?.team_name} {item?.score}
                            <br />
                          </span>
                        )
                      )}
                    </p>
                  </div>
                  <div
                    className="ldg-tbl-col col-lg-3 col-xs-4 col-md-3 col-sm-4 current-event-box bet_status_box"
                    style={{ paddingRight: 5, paddingLeft: "3px !important" }}
                  >
                    <div
                      className="inplay-show-place-bet"
                      style={{
                        backgroundColor: "rgb(58, 97, 162)",
                        color: "rgb(255, 255, 255)",
                        fontSize: 30,
                      }}
                      id="event_table_1436644"
                    >
                      {scoreData?.data?.response?.data?.data?.msg}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button
                    style={{ marginRight: 10 }}
                    className="btn btn-primary"
                    onClick={() => setIsBookMakerOpen(true)}
                  >
                    Book Makers Book
                  </button>
                  <a
                    onClick={() =>
                      navigate(
                        `/agent/dashboard/matches/${
                          matchDetails?.cricketId
                        }/current_bets`,
                        {
                          state: { matchDetails },
                        }
                      )
                    }
                  >
                    <button className="btn btn-primary">
                      View More Bets
                    </button>
                  </a>
                </div>
                <div className="row scroll-form">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th
                          colSpan={4}
                          className="bet-place-tbl-th align-left"
                          style={{ backgroundColor: "#6E8FC9" }}
                        >
                          Market : Match Winner
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          RUNNER
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          LAGAI
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          KHAI
                        </th>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          POSITION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchOdds &&
                        matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                          (match, index) => (
                            <tr>
                              <td
                                data-column="runner_name"
                                className="bet-place-tbl-td active"
                                style={{ textAlign: "left" }}
                              >
                                {match?.runnerName}
                              </td>
                              <td
                                data-runner-sid="Multan Sultans  _1436644"
                                data-column="back"
                                className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                                odd-val="1.27"
                                market-id={1594789}
                                runner-id={8933387}
                                match-id={1436644}
                                data-id="LAGAI"
                                id="Match Winner_1436644_back_Multan Sultans  _1436644"
                              >
                                {match?.status == "ACTIVE" &&
                                  parseFloat(match?.back1 / 100).toFixed(2)}
                              </td>
                              <td
                                data-runner-sid="Multan Sultans  _1436644"
                                data-column="lay"
                                className="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                                odd-val="1.29"
                                market-id={1594789}
                                runner-id={8933387}
                                match-id={1436644}
                                data-id="KHAI"
                                id="Match Winner_1436644_lay_Multan Sultans  _1436644"
                              >
                                {match?.status == "ACTIVE" &&
                                  parseFloat(match?.lay1 / 100).toFixed(2)}
                              </td>
                              <td
                                data-runner-sid="Multan Sultans  _1436644"
                                data-column="position"
                                className="bet-place-tbl-td  bet-val-cent"
                                data-position={0.0}
                              >
                                {matchData
                                  ?.find(
                                    (item) =>
                                      item.marketIdExternal ==
                                      matchOdds?.bookMakerOdds[0]?.bm1
                                        ?.marketId
                                  )
                                  ?.runnerPlExposureDTOS?.find(
                                    (pl) =>
                                      pl.selectionId == match?.selectionId
                                  )?.exposure &&
                                  parseFloat(
                                    matchData
                                      ?.find(
                                        (item) =>
                                          item.marketIdExternal ==
                                          matchOdds?.bookMakerOdds[0]?.bm1
                                            ?.marketId
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (pl) =>
                                          pl.selectionId == match?.selectionId
                                      )?.exposure
                                  ).toFixed(2)}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                {/*              MATCH WINNER MARKET CODE END            */}
                {/*-          START UNDECLARED SESSIONS TABLE            */}
                <div className="row session-resp-tbl table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          colSpan={10}
                          className="bet-place-tbl-th market_type_row session-th-strip align-left"
                        >
                          PENDING SESSIONS
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          {" "}
                          SESSION
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">No</th>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          RATE
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          Yes
                        </th>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          RATE
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          Position NO
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          Position Yes
                        </th>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchOdds?.fancyOdds
                        ?.find((item) => item?.gameType == "Normal")
                        ?.oddDetailsDTOS?.map(
                          (match) =>
                            match?.status != "SUSPENDED" && (
                              <tr>
                                <td
                                  data-column="session_title"
                                  className="bet-place-tbl-td"
                                  style={{ textAlign: "left" }}
                                >
                                  {match?.runnerName}
                                  <br />
                                  <span
                                    style={{
                                      color:
                                        matchData?.find(
                                          (item) =>
                                            item.marketIdExternal ==
                                            match?.marketId
                                        )?.exposure < 0
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {
                                      matchData?.find(
                                        (item) =>
                                          item.marketIdExternal ==
                                          match?.marketId
                                      )?.exposure
                                    }
                                  </span>
                                  <br />
                                </td>
                                <td
                                  className={`SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer ${(match?.status ==
                                    "SUSPENDED" ||
                                    match?.status == "Ball Running") &&
                                    "close-status"}`}
                                >
                                  {match?.status != "SUSPENDED" &&
                                    match?.status != "Ball Running" &&
                                    parseInt(match?.lay1)}
                                </td>
                                <td
                                  className={`${match?.status !=
                                    "SUSPENDED" &&
                                    match?.status != "Ball Running" &&
                                    "close-status"} no_rate bet-place-tbl-td bet-val-cent`}
                                >
                                  {match?.status != "SUSPENDED" &&
                                    match?.status != "Ball Running" &&
                                    parseFloat(match?.laySize1 / 100).toFixed(
                                      2
                                    )}
                                </td>
                                <td
                                  className={`${(match?.status ==
                                    "SUSPENDED" ||
                                    match?.status == "Ball Running") &&
                                    "close-status"} SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer`}
                                >
                                  {match?.status != "SUSPENDED" &&
                                    match?.status != "Ball Running" &&
                                    parseInt(match?.back1)}
                                </td>
                                <td
                                  data-column="yes_rate"
                                  className="yes_rate bet-place-tbl-td bet-val-cent"
                                  id="286662_yes_rate"
                                >
                                  {match?.status != "SUSPENDED" &&
                                    match?.status != "Ball Running" &&
                                    parseFloat(
                                      match?.backSize1 / 100
                                    ).toFixed(2)}
                                </td>
                                <td
                                  className="bet-place-tbl-td bet-val-cent"
                                  id="session_no_runns_286662"
                                >
                                  0.0
                                </td>
                                <td
                                  className="bet-place-tbl-td bet-val-cent"
                                  id="session_yes_runns_286662"
                                >
                                  0.0
                                </td>
                                <td className="bet-place-tbl-td">
                                  <a
                                    className="btn btn-info"
                                    data-method="get"
                                    data-remote="true"
                                    onClick={() => {
                                      let exposureData;

                                      for (
                                        let i = 0;
                                        i < matchData.length;
                                        i++
                                      ) {
                                        let fancyMarket = matchData[i];
                                        let market = matchOdds?.fancyOdds?.find(
                                          (item) => item?.gameType == "Normal"
                                        );
                                        for (
                                          let j = 0;
                                          j < market?.oddDetailsDTOS.length;
                                          j++
                                        ) {
                                          let odd = market.oddDetailsDTOS[j];
                                          if (
                                            odd?.marketId ==
                                            fancyMarket?.marketIdExternal
                                          ) {
                                            exposureData = {
                                              ...fancyMarket,
                                              title: odd.runnerName,
                                            };
                                            break;
                                          }
                                        }
                                      }

                                      setPositionModal(exposureData);
                                    }}
                                  >
                                    POSITION
                                  </a>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>

                <div
                  className="row session-resp-tbl table-responsive"
                  style={{ paddingTop: 15 }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          colSpan={10}
                          className="bet-place-tbl-th market_type_row session-th-strip align-left"
                        >
                          DECLARED SESSIONS
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="bet-place-tbl-th"
                          style={{ backgroundColor: "#49494A" }}
                        >
                          SESSION
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          Settled at Runs
                        </th>
                        <th className="bet-odds-type bet-place-tbl-th">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {settledFancy?.markets?.map((item) => (
                        <tr>
                          <td
                            data-column="session_title"
                            className="bet-place-tbl-td"
                            style={{ textAlign: "left" }}
                          >
                            {item?.marketName}
                          </td>
                          <td className="bet-place-tbl-td bet-val-cent">
                            {item?.result}
                          </td>
                          <td className="bet-place-tbl-td bet-val-cent">
                            {parseFloat(item?.status).toFixed(2)}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td className="bet-place-tbl-td bet-val-cent">
                          Declare Session Total
                        </td>
                        <td className="bet-place-tbl-td bet-val-cent" />
                        <td className="bet-place-tbl-td bet-val-cent">
                          {settledFancy?.totalProfitLoss}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: 30, margin: "auto auto" }}
              />
            </div>
          </div>

          {positionModal && (
            <div className="dynamic_modal">
              <div
                class="modal fade in"
                id="exampleModalLong"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
                style={{
                  paddingRight: "15px",
                  display: "block",
                  width: "100vw",
                  overflowY: "scroll",
                }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      {positionModal?.title}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setPositionModal(null)}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>RUNS</th>
                          <th>POSITION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {positionModal?.runnerPlExposureDTOS?.map((item) => (
                          <tr>
                            <td>{item?.selectionName}</td>
                            <td
                              style={{
                                color: item?.exposure < 0 ? "red" : "green",
                              }}
                            >
                              {parseFloat(item?.exposure).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={() => setPositionModal(null)}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
