import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientBetsCricketAPI } from "../service/cricket";
import Select from "react-select";
import moment from "moment";
import ReactSelect from "react-select";

export default function ViewSessionReport() {
  const [clients, setClients] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [isFancy, setIsFancy] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [payload, setPayload] = useState({});
  const [bets, setBets] = useState([]);

  const params = useParams();

  var gameType = "cricket";
  var eventId = params?.id;
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }

  const getClientBets = async (
    betStatus_,
    username,
    fancyTypes,
    filterWithoutCall = false
  ) => {
    if (filterWithoutCall) {
      if (username == "false" || fancyTypes == "false") {
        setClientBets(bets);
      } else {
        if (fancyTypes || username) {
          let bets_ = [...bets.bets];
          if (username) {
            bets_ = bets_.filter((item) => item.userName === username);
          }
          if (fancyTypes) {
            bets_ = bets_.filter((item) => item.runnerName === fancyTypes);
          }

          setClientBets({
            ...clientBets,
            bets: bets_,
          });
        }
      }
    } else {
      const { response } = await getClientBetsCricketAPI(
        eventId,
        isFancy,
        betStatus_ ? betStatus_ : betStatus
      );
      if (response) {
        setBets(response);
        setClients(
          Array.from(new Set(response?.bets?.map((item) => item?.userName)))
        );
        setSessions(
          Array.from(new Set(response?.bets?.map((item) => item?.runnerName)))
        );

        if (fancyTypes || username) {
          let bets_ = response;
          if (username) {
            bets_.bets = response.bets.filter(
              (item) => item.userName === username
            );
          }
          if (fancyTypes) {
            bets_.bets = response.bets.filter(
              (item) => item.runnerName === fancyTypes
            );
          }
          setClientBets(bets_);
        } else {
          setClientBets(response);
        }

        const totalAmount = response?.bets?.reduce(
          (total, item) => total + item.amount,
          0
        );

        setTotalAmount(totalAmount);
      }
    }
  };

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-view-session-bet
        _nghost-ng-c579518053=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c579518053="" className="page_header">
          <h1 _ngcontent-ng-c579518053="" className="page_heading">
            Session Bet Details
          </h1>
          <div _ngcontent-ng-c579518053="" className="breadcrumb_block">
            <nav _ngcontent-ng-c579518053="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c579518053="" className="breadcrumb">
                <li _ngcontent-ng-c579518053="" className="breadcrumb-item">
                  <a _ngcontent-ng-c579518053="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c579518053="" className="breadcrumb-item">
                  <a _ngcontent-ng-c579518053="">Sport's Betting </a>
                </li>
                <li
                  _ngcontent-ng-c579518053=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Session Bets
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div _ngcontent-ng-c579518053="" className="top_btn_block">
          <button
            _ngcontent-ng-c579518053=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Match &amp; Session Plus Minus
          </button>
          <button
            _ngcontent-ng-c579518053=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Match &amp; Session Bets
          </button>
          <button
            _ngcontent-ng-c579518053=""
            className="btn btn-primary"
            tabIndex={0}
            onClick={() => {
              window.location.href = `/game/i/${eventId}/viewMatchReport`;
            }}
          >
            Display Match Bets
          </button>
          <button
            _ngcontent-ng-c579518053=""
            className="btn btn-primary"
            tabIndex={0}
            onClick={() => {
              window.location.href = `/game/i/${eventId}/viewSessionReport`;
            }}
          >
            Display Session Bets
          </button>
        </div>
        <div
          _ngcontent-ng-c579518053=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c579518053=""
            className="row g-3 align-items-end mb-4"
          >
            <div
              _ngcontent-ng-c579518053=""
              className="col-sm-12 col-md-6 col-lg-3"
            >
              <div
                _ngcontent-ng-c579518053=""
                className="custom_select_block clearfix_input position-relative custom_select_block"
              >
                <label _ngcontent-ng-c579518053="">Client</label>
                <ReactSelect
                  id="name"
                  placeholder="Select Client"
                  name="client"
                  options={clients.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(e) => {
                    if (e.value)
                      setPayload({
                        ...payload,
                        client: e.value,
                      });
                    else setPayload({});
                  }}
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c579518053=""
              className="col-sm-12 col-md-6 col-lg-3"
            >
              <div
                _ngcontent-ng-c579518053=""
                className="input_block custom_select_block"
              >
                <label _ngcontent-ng-c579518053="">Session</label>
                <ReactSelect
                  id="name"
                  placeholder="Select Client"
                  name="client"
                  options={sessions.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      session: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c579518053=""
              className="col-sm-12 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c579518053="" className="input_block">
                <button
                  onClick={() =>
                    getClientBets(betStatus, payload.client, payload.session)
                  }
                  _ngcontent-ng-c579518053=""
                  type="button"
                  className="btn btn-primary"
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c579518053="" className="custom_table">
            <div _ngcontent-ng-c579518053="" className="table-responsive">
              <table _ngcontent-ng-c579518053="" className="table">
                <thead _ngcontent-ng-c579518053="">
                  <tr _ngcontent-ng-c579518053="">
                    <th _ngcontent-ng-c579518053="">Sr</th>
                    <th _ngcontent-ng-c579518053="">Client</th>
                    <th _ngcontent-ng-c579518053="">Session</th>
                    <th _ngcontent-ng-c579518053="">Rate</th>
                    <th _ngcontent-ng-c579518053="">Amount</th>
                    <th _ngcontent-ng-c579518053="">Runs</th>
                    <th _ngcontent-ng-c579518053="">Mode</th>
                    {clientBets?.bets?.[0]?.upperLineExposure?.map((item) => (
                      <th _ngcontent-ng-c579518053="">
                        {item?.selectionName}
                      </th>
                    ))}
                    <th _ngcontent-ng-c579518053="">Dec</th>
                    <th _ngcontent-ng-c579518053="">Date &amp; Time</th>
                    <th _ngcontent-ng-c579518053="">Agent</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c579518053="">
                  {clientBets?.bets?.map((item, index) => (
                    <tr _ngcontent-ng-c579518053="">
                      <td _ngcontent-ng-c579518053="">{index + 1}</td>
                      <td _ngcontent-ng-c579518053="">{item.userName}</td>
                      <td _ngcontent-ng-c579518053="">{item.runnerName}</td>
                      <td _ngcontent-ng-c579518053="">{item.rate}</td>
                      <td _ngcontent-ng-c579518053="">{item.amount}</td>
                      <td _ngcontent-ng-c579518053="">{item.run}</td>
                      <td _ngcontent-ng-c579518053="">
                        {item.back ? "YES" : "NO"}
                      </td>
                      {item?.upperLineExposure?.map((item) => (
                        <td _ngcontent-ng-c579518053="">
                          {Math.abs(item?.exposure)}
                        </td>
                      ))}
                      <td _ngcontent-ng-c579518053="">{item.winner}</td>
                      <td _ngcontent-ng-c579518053="">
                        {moment(item.placeDate + "Z").format(
                          "DD-MM-YYYY hh:mm:ss"
                        )}
                      </td>
                      <td _ngcontent-ng-c579518053="">{item?.agent}</td>
                    </tr>
                  ))}
                  <tr>
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="">
                      <b _ngcontent-ng-c579518053="">Total</b>
                    </td>
                    <td _ngcontent-ng-c579518053="">
                      <b _ngcontent-ng-c579518053="">
                        {clientBets?.bets?.reduce(
                          (total, item) =>
                            total +
                            item?.upperLineExposure?.reduce((total, item) => {
                              if (item.selectionName == "YES") {
                                return total + item.exposure;
                              } else {
                                return total;
                              }
                            }, 0),
                          0
                        )}
                      </b>
                    </td>
                    <td _ngcontent-ng-c579518053="">
                      <b _ngcontent-ng-c579518053="">
                        {clientBets?.bets?.reduce(
                          (total, item) =>
                            total +
                            item?.upperLineExposure?.reduce((total, item) => {
                              if (item.selectionName == "NO") {
                                return total + item.exposure;
                              } else {
                                return total;
                              }
                            }, 0),
                          0
                        )}
                      </b>
                    </td>
                    <td _ngcontent-ng-c579518053="" />
                    <td _ngcontent-ng-c579518053="" />
                  </tr>
                </tbody>
                <tbody />
              </table>
            </div>
          </div>
        </div>
      </app-view-session-bet>
      {/**/}
    </div>
  );
}
