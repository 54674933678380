import React, { useEffect } from "react";
import { getAllClientBetsAPI } from "../service/cricket";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

export default function MatchBetSlip() {
  const params = useParams();
  const location = useLocation();
  const [betSlips, setBetSlips] = React.useState([]);
  const [betStatus, setBetStatus] = React.useState("MATCHED");
  const [isFancy, setIsFancy] = React.useState(location?.state?.isFancy);
  const [userNamesOptions, setUserNamesOptions] = React.useState([]);
  const [marketOptions, setMarketOptions] = React.useState([]);
  const userData = useSelector((state) => state.account.userData);
  const [totalLength, setTotalLength] = React.useState(0);
  const [uniqueRunnerNames, setUniqueRunnerNames] = React.useState([]);
  const getAllClientBets = async (userFilter, marketFilter) => {
    try {
      const { response } = await getAllClientBetsAPI(
        params.id,
        betStatus,
        isFancy
      );
      let uniqueUserNames = [];
      response.bets.map((user) => {
        if (!uniqueUserNames.includes(user.userName)) {
          uniqueUserNames.push(user.userName);
        }
      });
      let uniqueMarkets = [];
      response.bets.map((market) => {
        if (!uniqueMarkets.includes(market.runnerName)) {
          uniqueMarkets.push(market.runnerName);
        }
      });
      if (userFilter) {
        response.bets = response.bets.filter(
          (user) => user.userName === userFilter
        );
      }
      if (marketFilter) {
        response.bets = response.bets.filter(
          (user) => user.runnerName === marketFilter
        );
      }
      if (
        response.bets.length != 0 &&
        response.bets[0]?.parentExposure?.length > 0
      ) {
        setTotalLength(response.bets[0]?.parentExposure.length);
      }
      setMarketOptions(uniqueMarkets);
      setUserNamesOptions(uniqueUserNames);
      setBetSlips(response);
      setUniqueRunnerNames(response.bets[0]?.parentExposure);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAllClientBets();
  }, []);
  console.log(userData);

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>BET SLIPS</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="/agent/match/matches/">Matches</a>
            </li>
            <li>
              <a href={`/agent/matches/${params.id}/live_report12`}>
                {location.state?.match?.eventName}
              </a>
            </li>
            <li>
              <a href="#">
                <strong>
                  <strong>Bet Slips</strong>
                </strong>
              </a>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Total Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {betSlips?.settledBets + betSlips?.unsettledBets}
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Settled Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">{betSlips?.settledBets}</h1>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Unsettled Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">{betSlips?.unsettledBets}</h1>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Reverted Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">{betSlips?.revertedBets}</h1>
            </div>
          </div>
        </div> */}
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-sm-5 m-b-xs">
            <select
              className="input-sm form-control input-s-sm inline"
              id="user_ids"
              name="user_id"
              style={{
                height: 34,
              }}
              onChange={(e) => {
                getAllClientBets(e.target.value);
              }}
            >
              <option value={""}>All Users</option>
              {userNamesOptions?.map((user) => (
                <option value={user}>{user}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-5 m-b-xs">
            <select
              className="input-sm form-control input-s-sm inline"
              id="market_ids"
              name="model[field]"
              style={{
                height: 34,
              }}
              onChange={(e) => {
                isFancy && getAllClientBets(null, e.target.value);
              }}
            >
              <option onClick={() => getAllClientBets()} value={""}>
                All Markets
              </option>
              {!isFancy
                ? uniqueRunnerNames?.map((market) => (
                    <option value={market.selectionName}>
                      {market.selectionName}
                    </option>
                  ))
                : marketOptions?.map((market) => (
                    <option value={market}>{market}</option>
                  ))}
            </select>
          </div>
        </div>
        {!isFancy && (
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>Market Position</h5>
                  <div className="ibox-tools" />
                </div>
                <div className="ibox-content table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>RUNNER</th>
                        <th>POSITION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from({ length: totalLength }).map((_, index) => (
                        <tr>
                          <td>
                            {
                              betSlips?.bets?.[0]?.parentExposure[index]
                                ?.selectionName
                            }
                          </td>
                          <td>
                            {" "}
                            {betSlips?.bets?.reduce(
                              (acc, item) =>
                                acc + item?.parentExposure[index]?.exposure,
                              0
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>Bet Slips</h5>
              <div class="ibox-tools" />
            </div>

            <div class="ibox-content table-responsive ">
              {isFancy ? (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr className="tbl-header-middle">
                      <th colSpan={10}>Bet</th>
                      <th colSpan={3}>My Share / Position</th>
                      <th colSpan={2}>Settlement</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Bet ID</th>
                      <th>DATE</th>
                      <th>User</th>
                      <th>Session Title</th>
                      <th>RATE</th>
                      <th>RUNS</th>
                      <th>AMOUNT</th>
                      <th>MODE</th>
                      <th>No</th>
                      <th>Yes</th>
                      <th>My Share</th>
                      <th>No</th>
                      <th>Yes</th>
                      <th>Status</th>
                      <th>Plus / Minus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betSlips?.bets?.map((item) => (
                      <tr>
                        <td>{item?.id}</td>
                        <td>
                          <i className="fa fa-clock-o" />
                          &nbsp;
                          {moment(item?.placeDate + "Z").format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                        <td>{item?.userName}</td>
                        <td>{item?.runnerName}</td>
                        <td>{item?.rate}</td>
                        <td>{item?.run}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.back ? "YES" : "NO"}</td>
                        {item?.upperLineExposure?.map((_) => (
                          <td>{_?.exposure}</td>
                        ))}
                        <td>{item?.myShare} %</td>
                        {item?.parentExposure?.map((_) => (
                          <td>{_?.exposure}</td>
                        ))}
                        <td>
                          <span
                            className={`label ${
                              item?.status
                                ? "label-primary"
                                : "label-secondary"
                            }`}
                          >
                            {!item?.status ? "Not Settled" : "Settled"}
                          </span>
                        </td>
                        <td>{item?.plusMinus}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th />
                      <th />
                      <th>Total</th>
                      <th />
                      <th />
                      <th />
                      <th>
                        {betSlips?.bets?.reduce(
                          (acc, item) => acc + item?.amount,
                          0
                        )}
                      </th>
                      <th />
                      {Array.from({ length: totalLength }).map((_, index) => (
                        <th>
                          {betSlips?.bets?.reduce(
                            (acc, item) =>
                              acc + item?.upperLineExposure[index]?.exposure,
                            0
                          )}
                        </th>
                      ))}

                      <th />
                      {Array.from({ length: totalLength }).map((_, index) => (
                        <th>
                          {betSlips?.bets?.reduce(
                            (acc, item) =>
                              acc + item?.parentExposure[index]?.exposure,
                            0
                          )}
                        </th>
                      ))}
                      <th />
                      <th>
                        {betSlips?.bets?.reduce(
                          (acc, item) => acc + item?.plusMinus,
                          0
                        )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th colspan="10">Bet</th>
                      <th colspan="3">My Share / Position</th>
                      <th colspan="2">Settlement</th>
                    </tr>
                  </thead>

                  <thead>
                    <tr>
                      <th>Bet ID</th>
                      <th>DATE</th>
                      <th>Market Title</th>
                      <th>RATE</th>
                      <th>AMOUNT</th>
                      <th>MODE</th>
                      <th>Runner Name</th>
                      <th>User</th>
                      {uniqueRunnerNames?.map((item, index) => (
                        <th>{item.selectionName}</th>
                      ))}
                      <th>My Share</th>
                      {uniqueRunnerNames?.map((item, index) => (
                        <th>{item.selectionName}</th>
                      ))}
                      <th>Status</th>
                      <th>Plus / Minus</th>
                    </tr>
                  </thead>

                  <tbody>
                    {betSlips?.bets?.map((item) => (
                      <tr>
                        <td>{item?.id}</td>
                        <td>
                          <i class="fa fa-clock-o" />
                          &nbsp;
                          {moment(item?.placeDate + "Z").format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                        <td>{item?.marketName}</td>
                        <td>{item?.rate}</td>
                        <td>{item?.amount}</td>
                        <td>
                          {!isFancy
                            ? item?.back
                              ? "LAGAI"
                              : "KHAI"
                            : item?.back
                            ? "YES"
                            : "NO"}
                        </td>
                        <td>{item?.runnerName}</td>
                        <td>{item?.userName}</td>

                        {item?.upperLineExposure?.map((_) => (
                          <th>{_?.exposure}</th>
                        ))}

                        <td>{item?.myShare} %</td>
                        {item?.parentExposure?.map((_) => (
                          <th className="runner-sum">{_?.exposure}</th>
                        ))}
                        <td>
                          <span
                            class={`label ${
                              item?.status
                                ? "label-primary"
                                : "label-secondary"
                            }`}
                          >
                            {!item?.status ? "Not Settled" : "Settled"}
                          </span>
                        </td>
                        <td>{item?.plusMinus}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th />
                      <th />
                      <th />
                      <th />
                      <th />
                      <th />
                      <th />
                      <th>Total</th>
                      {Array.from({ length: totalLength }).map((_, index) => (
                        <th>
                          {betSlips?.bets?.reduce(
                            (acc, item) =>
                              acc + item?.upperLineExposure[index]?.exposure,
                            0
                          )}
                        </th>
                      ))}

                      <th> </th>
                      {Array.from({ length: totalLength }).map((_, index) => (
                        <th>
                          {betSlips?.bets?.reduce(
                            (acc, item) =>
                              acc + item?.parentExposure[index]?.exposure,
                            0
                          )}
                        </th>
                      ))}
                      <th />
                      <th>
                        {betSlips?.bets?.reduce(
                          (acc, item) => acc + item?.plusMinus,
                          0
                        )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
