import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPlusMinusReportAPI } from "../service/AuthService";
import { PERMISSION_MAP } from "../utils/constants";
import { useSelector } from "react-redux";
const colorMap = [
  "dark",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "danger",
  "indigo",
  "light-blue",
];
let tableData = [];
export default function MatchSessionPlusMinusDisplayNew({ isCasino }) {
  const params = useParams();
  const userData = useSelector((state) => state.account.userData);
  const { id } = params;
  const location = useLocation();
  const [data, setData] = React.useState([]);

  const getData = async () => {
    const { response } = await getPlusMinusReportAPI(id, {
      marketIds: location?.state?.selected,
      userIds: location?.state?.selectedUsers,
      isBoth: true,
      isCasino,
    });
    setData(buildTree(response));
  };

  const buildTree = (node) => {
    const tree = {
      name: node.name,
      children: [],
      total: node.total,
      tableData: [],
      color: colorMap[Math.floor(Math.random() * colorMap.length)],
    };

    if (node.plusMinus && node?.plusMinus.length > 0) {
      if (node?.plusMinus?.length > 0) {
        node.plusMinus.forEach((childNode) => {
          tree.children.push(buildTree(childNode));
          if (!childNode.total) {
            tree.tableData.push(childNode);
            tableData.push(childNode);
          }
        });
      }
    }
    return tree;
  };

  useEffect(() => {
    getData();
  }, [0]);
  return (
    <div className="main_content">
      <app-match-session-plus-minus
        _nghost-ng-c3257282669=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c3257282669="" className="page_header">
          <h1 _ngcontent-ng-c3257282669="" className="page_heading">
            Match &amp; Session Plus Minus Report
          </h1>
          <div _ngcontent-ng-c3257282669="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3257282669="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3257282669="" className="breadcrumb">
                <li _ngcontent-ng-c3257282669="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3257282669="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c3257282669="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3257282669="">Sport's Betting </a>
                </li>
                <li
                  _ngcontent-ng-c3257282669=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Match &amp; Session Plus Minus Selection
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div _ngcontent-ng-c3257282669="" className="top_btn_block">
          <button
            _ngcontent-ng-c3257282669=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Match &amp; Session Plus Minus
          </button>
          <button
            _ngcontent-ng-c3257282669=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Match &amp; Session Bets
          </button>
          <button
            _ngcontent-ng-c3257282669=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Match Bets
          </button>
          <button
            _ngcontent-ng-c3257282669=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Session Bets
          </button>
        </div>
        <div
          _ngcontent-ng-c3257282669=""
          className="Super_master_details_section agentledger_block agentledger_match_session"
        >
          <div _ngcontent-ng-c3257282669="" className="row-fluid">
            <div _ngcontent-ng-c3257282669="" className="span12">
              <div _ngcontent-ng-c3257282669="" className="widget">
                <div _ngcontent-ng-c3257282669="" className="widget-body form">
                  <div _ngcontent-ng-c3257282669="" className="portlet-body">
                    <div _ngcontent-ng-c3257282669="" className="clearfix" />
                    <div _ngcontent-ng-c3257282669="" className="space15" />
                  </div>
                  <div
                    _ngcontent-ng-c3257282669=""
                    className="table-responsive custom_table"
                  >
                    <table
                      _ngcontent-ng-c3257282669=""
                      className="table table-striped table-hover table-bordered no_space"
                    >
                      <tbody _ngcontent-ng-c3257282669="">
                        <tr _ngcontent-ng-c3257282669="">
                          <td
                            _ngcontent-ng-c3257282669=""
                            height={25}
                            width="70%"
                            className="tab_bg_theem"
                            style={{
                              textAlign: "left",
                              verticalAlign: "middle",
                            }}
                          >
                            <strong _ngcontent-ng-c3257282669="">
                              {" "}
                              T20 -&gt; Bulawayo Brave Jaguars v Durban Wolves
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    _ngcontent-ng-c3257282669=""
                    className="table-responsive custom_table ng-star-inserted"
                  >
                    <table
                      _ngcontent-ng-c3257282669=""
                      width="99%"
                      cellPadding={0}
                      cellSpacing={0}
                      className="table table-striped table-bordered no_space"
                    >
                      <tbody _ngcontent-ng-c3257282669="">
                        <tr _ngcontent-ng-c3257282669="">
                          <td
                            _ngcontent-ng-c3257282669=""
                            width="7%"
                            height={25}
                            className="tab_bg_theem"
                          >
                            <strong _ngcontent-ng-c3257282669="">Admin</strong>
                          </td>
                          <td
                            _ngcontent-ng-c3257282669=""
                            width={630}
                            className="tab_bg_theem"
                          >
                            <strong _ngcontent-ng-c3257282669="">
                              {" "}
                              ADM548060{" "}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    _ngcontent-ng-c3257282669=""
                    className="table-responsive custom_table ng-star-inserted"
                  >
                    <table
                      _ngcontent-ng-c3257282669=""
                      width="99%"
                      cellPadding={0}
                      cellSpacing={0}
                      className="table table-striped table-bordered"
                      style={{ marginBottom: "20px !important" }}
                    >
                      <tbody _ngcontent-ng-c3257282669="">
                        <tr _ngcontent-ng-c3257282669="">
                          <td
                            _ngcontent-ng-c3257282669=""
                            width="7%"
                            height={25}
                            className="tab_bg_theem"
                          >
                            <strong _ngcontent-ng-c3257282669="">Admin</strong>
                          </td>
                          <td
                            _ngcontent-ng-c3257282669=""
                            width={630}
                            className="tab_bg_theem"
                          >
                            <strong _ngcontent-ng-c3257282669="">
                              100rav(ADM548060)
                            </strong>
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c3257282669="">
                          <td
                            _ngcontent-ng-c3257282669=""
                            height={25}
                            colSpan={2}
                            style={{ padding: 0 }}
                          >
                            <table
                              _ngcontent-ng-c3257282669=""
                              width="100%"
                              cellPadding={0}
                              cellSpacing={2}
                              className="table table-striped table-bordered"
                              style={{ paddingBottom: 100 }}
                            >
                              <tbody _ngcontent-ng-c3257282669="">
                                <tr _ngcontent-ng-c3257282669="">
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    height={25}
                                    className="tab_bg_theem"
                                  >
                                    {" "}
                                    &nbsp;{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    colSpan={10}
                                    className="tab_bg_theem ng-star-inserted"
                                    style={{ textAlign: "center" }}
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      {" "}
                                      Sub Admin PLUS MINUS
                                    </strong>
                                  </td>
                                  {/**/}
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    colSpan={7}
                                    className="tab_bg_theem"
                                    style={{ textAlign: "center" }}
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      {" "}
                                      Admin PLUS MINUS
                                    </strong>
                                  </td>
                                </tr>
                                <tr _ngcontent-ng-c3257282669="">
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={180}
                                    height={25}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      CLIENT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      M AMT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      SESS.
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      TOT. AMT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      M. COM
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      S. COM
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      TOL. COM
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      NET AMT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      SHR AMT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      FINAL
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      M. COM
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      S. COM
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      TOL. COM
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      NET AMT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      SHR AMT
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="tab_bg_theem"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      FINAL
                                    </strong>
                                  </td>
                                </tr>
                                <tr
                                  _ngcontent-ng-c3257282669=""
                                  className="ng-star-inserted"
                                >
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    height={25}
                                    className="FontText"
                                  >
                                    <span
                                      _ngcontent-ng-c3257282669=""
                                      className="btn btn-sm h-auto px-2 py-0 ng-star-inserted"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                      }}
                                    >
                                      <b _ngcontent-ng-c3257282669="">+</b>
                                    </span>
                                    {/**/}
                                    {/**/} SDM763144
                                    <br _ngcontent-ng-c3257282669="" />
                                    Jmd nisthu{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    {" "}
                                    12,165.52
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    {" "}
                                    105,387.00
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      117,552.52
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -272.84
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -4,751.25{" "}
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -5,024.09
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      112,528.43
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value ng-star-inserted"
                                  >
                                    {" "}
                                    105,776.71{" "}
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      6,751.72
                                    </strong>
                                  </td>
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -272.84
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -4,751.25{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -5,024.09
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      112,528.43
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    {" "}
                                    106,902.01{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      5,626.42
                                    </strong>
                                  </td>
                                </tr>
                                {/**/}
                                <tr
                                  _ngcontent-ng-c3257282669=""
                                  className="ng-star-inserted"
                                >
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    height={25}
                                    className="FontText"
                                  >
                                    <span
                                      _ngcontent-ng-c3257282669=""
                                      className="btn btn-sm h-auto px-2 py-0 ng-star-inserted"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                      }}
                                    >
                                      <b _ngcontent-ng-c3257282669="">+</b>
                                    </span>
                                    {/**/}
                                    {/**/} SDM330048
                                    <br _ngcontent-ng-c3257282669="" />
                                    Gulli bhai{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -3,661.50
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -4,542.10
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -8,203.60
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -2.88
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -501.15{" "}
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -504.03
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -8,707.63
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -8,185.15{" "}
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -522.48
                                    </strong>
                                  </td>
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -2.88
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -501.15{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -504.03
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -8,707.63
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -8,272.25{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -435.38
                                    </strong>
                                  </td>
                                </tr>
                                {/**/}
                                <tr
                                  _ngcontent-ng-c3257282669=""
                                  className="ng-star-inserted"
                                >
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    height={25}
                                    className="FontText"
                                  >
                                    <span
                                      _ngcontent-ng-c3257282669=""
                                      className="btn btn-sm h-auto px-2 py-0 ng-star-inserted"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                      }}
                                    >
                                      <b _ngcontent-ng-c3257282669="">+</b>
                                    </span>
                                    {/**/}
                                    {/**/} SDM236311
                                    <br _ngcontent-ng-c3257282669="" />
                                    Bpj aksh bhai{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText plus_value"
                                  >
                                    {" "}
                                    75.00
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -1,000.00
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -925.00
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -1.50
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -36.00{" "}
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -37.50
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -962.50
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    {" "}
                                    -875.87{" "}
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -86.63
                                    </strong>
                                  </td>
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -1.50
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -36.00{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -37.50
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -962.50
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    {" "}
                                    -914.37{" "}
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    className="FontText mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -48.13
                                    </strong>
                                  </td>
                                </tr>
                                {/**/}
                                {/**/}
                                <tr
                                  _ngcontent-ng-c3257282669=""
                                  className="ng-star-inserted"
                                >
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={180}
                                    height={25}
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      TOTAL
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      8,579.02
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      99,844.90
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      108,423.92
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -277.22
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -5,288.40
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="mainus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -5,565.62
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      102,858.30
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      96,715.69
                                    </strong>
                                  </td>
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value ng-star-inserted"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      6,142.61
                                    </strong>
                                  </td>
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  {/**/}
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -277.22
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -5,288.40
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="mainus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      -5,565.62
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      102,858.30
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      97,715.39
                                    </strong>
                                  </td>
                                  <td
                                    _ngcontent-ng-c3257282669=""
                                    width={100}
                                    className="plus_value"
                                  >
                                    <strong _ngcontent-ng-c3257282669="">
                                      5,142.91
                                    </strong>
                                  </td>
                                </tr>
                                {/**/}
                                {/**/}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-match-session-plus-minus>
    </div>
  );
}
function TreeNode({ node, index }) {
  return (
    <div
      style={{ width: "min-content" }}
      className={`card card-${node.color} bg-light`}
    >
      <div className="card-header text-center">
        <h5 className="card-title">{node.name}</h5>
      </div>
      <div className="card-body">
        {node.children &&
          node.children.map((child) => {
            return (
              child?.name &&
              child?.total &&
              Object.keys(child?.total?.userTypePlusMinusBaseDTOMap).length >
                0 &&
              child?.children.length > 0 && (
                <TreeNode key={child.name} node={child} />
              )
            );
          })}
        {node.tableData?.length > 0 && (
          <table id="data" className="table table-striped table-bordered">
            <thead>
              <tr>
                <th colspan={"10"} class="text-center">
                  Agent PlusMinus
                </th>
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  ?.SUPER_AGENT && (
                  <th colspan="6" class="text-center">
                    Super Agent PlusMinus
                  </th>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.MASTER && (
                  <th colspan="6" class="text-center">
                    Master Agent PlusMinus
                  </th>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                  <th colspan="6" class="text-center">
                    Sub Admin PlusMinus
                  </th>
                )}

                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                  <th colspan="6" class="text-center">
                    Admin
                  </th>
                )}

                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  .SUPER_ADMIN && (
                  <th colspan="6" class="text-center">
                    Super Admin
                  </th>
                )}
              </tr>
            </thead>
            <thead>
              <tr>
                <th>CLIENT</th>
                <th>M AMT</th>
                <th>S AMT</th>
                <th>TOT AMT</th>
                <th>M COM</th>
                <th>S COM</th>
                <th>TOT COM</th>
                <th>NET AMT</th>
                <th>SHR AMT</th>
                <th>FINAL</th>
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  .SUPER_AGENT && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.MASTER && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  ?.SUPER_ADMIN && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {node.tableData?.map((item) => (
                <tr>
                  <td>{item?.clientUsernameAndName}</td>
                  <td>{parseFloat(item?.matchAmount).toFixed(2)}</td>
                  <td>{parseFloat(item?.sessionAmount).toFixed(2)}</td>
                  <td>{parseFloat(item?.totalAmount).toFixed(2)}</td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.matchCommission
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT
                        ?.sessionCommission
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.totalCommission
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.netAmount
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.shareAmount
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.finalAmount
                    ).toFixed(2)}
                  </td>
                  {item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.MASTER && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>{node?.total?.clientUsernameAndName}</th>

                <th>{parseFloat(node?.total?.matchAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.sessionAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.totalAmount).toFixed(2)}</th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.matchCommission
                  ).toFixed(2)}
                </th>

                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.finalAmount
                  ).toFixed(2)}
                </th>
                {node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.MASTER && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
              </tr>
            </tfoot>
          </table>
        )}
      </div>
      {node?.tableData?.length == 0 && node.children.length > 0 && (
        <div className="card-footer">
          <table className="table table-striped table-bordered">
            <thead className="bg-gradient-white">
              <tr>
                <th>{node?.total?.clientUsernameAndName}</th>
                <th>{parseFloat(node?.total?.matchAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.sessionAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.totalAmount).toFixed(2)}</th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      )}
    </div>
  );
}

function Tree({ tree, index }) {
  return (
    <div>
      <TreeNode node={tree} index={index} />
    </div>
  );
}
