import React from "react";

export default function SendLoginDetails({ show, setShow }) {
  const shareDetails = (message) => {
    let url = `https://api.whatsapp.com/send?text=${message}`;
    window.open(url, "_blank");
  };
  return (
    <div className="ant-modal-root snipcss-UKMQw">
      <div className="ant-modal-mask" />
      <div tabIndex={-1} className="ant-modal-wrap">
        <div
          role="dialog"
          aria-labelledby="rc_unique_1"
          aria-modal="true"
          className="ant-modal gx-px-3 style-1GDWy"
          id="style-1GDWy"
        >
          <div
            tabIndex={0}
            aria-hidden="true"
            id="style-Zo9RE"
            className="style-Zo9RE"
          />
          <div
            style={{
              width: "50% !important",
              marginLeft: "25% !important",
            }}
            className="ant-modal-content"
          >
            <button
              type="button"
              aria-label="Close"
              onClick={() => {
                setShow(null);
              }}
              className="ant-modal-close"
            >
              <span className="ant-modal-close-x">
                <span
                  role="img"
                  aria-label="close"
                  className="anticon anticon-close ant-modal-close-icon"
                >
                  <svg
                    fillRule="evenodd"
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                  </svg>
                </span>
              </span>
            </button>
            <div className="ant-modal-header">
              <div className="ant-modal-title" id="rc_unique_1">
                Reset Password
              </div>
            </div>
            <div className="ant-modal-body">
              <textarea
                rows={7}
                readOnly=""
                className="ant-input"
                defaultValue={` New Password\n  LINK : ${
                  show?.userType?.toLowerCase() == "client"
                    ? `${process.env?.REACT_APP_NAME}.in`
                    : show?.userType?.toLowerCase() == "super agent"
                    ? "super." + process.env?.REACT_APP_NAME + ".in"
                    : `${show?.userType?.toLowerCase()}.${
                        process.env?.REACT_APP_NAME
                      }.in`
                }\n  ID : ${show?.username}\n  PW : ${show?.password}\nOTP:${
                  show?.otp
                } \n  `}
              />
            </div>
            <div className="ant-modal-footer">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `New Password\n  LINK : ${
                      show?.userType?.toLowerCase() == "client"
                        ? `${process.env?.REACT_APP_NAME}.in`
                        : show?.userType?.toLowerCase() == "super_agent"
                        ? "super." + process.env?.REACT_APP_NAME + ".in"
                        : `${show?.userType?.toLowerCase()}.${
                            process.env?.REACT_APP_NAME
                          }.in`
                    }\n  ID : ${show?.username}\n  PW : ${
                      show?.password
                    }\n  \n   `
                  );
                  shareDetails(
                    `New Password\n  LINK : ${
                      show?.userType?.toLowerCase() ==
                      "client"
                        ? `${process.env?.REACT_APP_NAME}.in`
                        : show?.userType?.toLowerCase() ==
                          "super agent"
                        ? "super." +
                          process.env?.REACT_APP_NAME +
                          ".in"
                        : `${show?.userType?.toLowerCase()}.${
                            process.env?.REACT_APP_NAME
                          }.in`
                    }\n  ID : ${show?.username}\n  PW : ${
                      show?.password
                    }\nOTP:${show?.otp} \n   `
                  );
                  setShow(null);
                }}
                className="ant-btn ant-btn-primary gx-pointer"
              >
                <span>Save &amp; Copy</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  setShow(null);
                }}
                className="ant-btn ant-btn-default gx-bg-grey gx-text-light-grey gx-pointer"
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
          <div
            tabIndex={0}
            aria-hidden="true"
            id="style-s9lB4"
            className="style-s9lB4"
          />
        </div>
      </div>
    </div>
  );
}
