import React, { useEffect, useState } from "react";
import {
  ChangeClientLimitAPI,
  ChangeClientStatusAPI,
  ClientWithdrawAndDepositAPI,
  getClientAPI,
} from "../service/UserService";
import { getUsersWithUserTypeAPI } from "../service/UserService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkActiveDeactiveAPI,
  updateCommissionAPI,
  updateUserStatusAPI,
  unSettledBetsAPI,
  sendLoginDetailsAPI,
  getSecureCodeByChildIdAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import store from "../redux/store";
import { PERMISSION_MAP } from "../utils/constants";
import { Spinner } from "@chakra-ui/react";
import moment from "moment";
import { useQuery } from "react-query";
import { Modal, ToggleButton } from "react-bootstrap";
import DWModal from "./components/DWModal";
import SendLoginDetails from "./components/SendLoginDetails";
import { Switch } from "@material-ui/core";
export default function MyClients() {
  const [search, setSearch] = useState("");
  const [userTypeName, setUserType] = useState("");
  const [clientData, setClientData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userTypeList, setGetUsersWithUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(50);
  const [isDWModalOpen, setIsDWModalOpen] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [userId, setGetUserId] = useState("");
  const [selectedClientDW, setSelectedClientDw] = useState();
  const location = useLocation();
  const { userType, userName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(location?.state?.userName || "");
  const [checkedItems, setCheckedItems] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [exposureTableModal, setExposureTableModal] = useState();
  const [userUnsettledBets, setUserUnsettledBets] = useState([]);
  const [sendLoginDetails, setSendLoginDetails] = useState(false);
  const [showSendLoginDetails, setShowSendLoginDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(
    Array(clientData?.childrenUserResponseDTOS?.length).fill(false)
  );
  const getUnsettledBetByUsername = async (username, name) => {
    const {
      response: { content },
    } = await unSettledBetsAPI({
      pageSize: 1000,
      pageNumber: 0,

      betStatus: "MATCHED",
    });
    setUserUnsettledBets(
      content?.filter((item) => item.userName == username + " " + name)
    );
  };

  const toggleDetails = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileUI(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUsersWithUserType = async () => {
    let userTypeGet;
    if (userType?.toUpperCase() === "SUB_ADMIN") {
      userTypeGet = "ADMIN";
    } else if (userType?.toUpperCase() === "MASTER") {
      userTypeGet = "SUB_ADMIN";
    } else if (userType?.toUpperCase() === "SUPER_AGENT") {
      userTypeGet = "MASTER";
    } else if (userType?.toUpperCase() === "AGENT") {
      userTypeGet = "SUPER_AGENT";
    } else if (userType?.toUpperCase() === "CLIENT") {
      userTypeGet = "AGENT";
    }
    setUserType(userTypeGet);
    const { response } = await getUsersWithUserTypeAPI(userTypeGet);
    let response_ = response;
    if (response_) {
      setGetUsersWithUsers(response_);
    }
  };
  const handleAllStatus = async (status) => {
    try {
      setIsLoading(true);
      const { response, code } = await bulkActiveDeactiveAPI(
        status,
        checkedItems.length == 0 ? userType?.toLocaleUpperCase() : null,
        checkedItems
      );
      if (code == 200) {
        getClient();
        window.location.reload();
        toast.success(`All ${userType} status changed`);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const handleUpdateCommission = async (amount, user) => {
    try {
      const { response, code } = await updateCommissionAPI({
        currentLimit: user?.isDeposit ? amount : -amount,
        id: user?.uid,
      });
      if (code === 200) {
        toast.success("Balance Updated Successfully");
        getClient();
        window.location.reload();
      } else {
        toast.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const handleActiveInactive = async (cli, betStatus) => {
    const { response, code } = await updateUserStatusAPI(
      cli?.uid,
      !cli?.isActive,
      cli?.userType == "CLIENT"
        ? betStatus
          ? betStatus
          : cli?.isBetLocked
        : true
    );
    if (code == 200) {
      getClient();
      window.location.reload();
      toast.success(`User status changed`);
    } else {
      toast.error(response);
    }
  };
  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      id,
      userName ? "" : userType?.toUpperCase(),
      location?.state?.blocked,
      isActive != null ? isActive : null,
      userName
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };
  const getOtp = async (id) => {};

  const navigate = useNavigate();
  useEffect(() => {
    getClient();
    getUsersWithUserType();
  }, [
    location.state,
    location.search,
    location.pathname,
    size,
    currentPage,
    isActive,
    search,
  ]);

  const handleSelectChangeUser = (e) => {
    const userID = e.target.value;
    setGetUserId(userID);
  };
  useEffect(() => {
    setShowDetails(
      Array(clientData?.childrenUserResponseDTOS?.length).fill(false)
    );
  }, [userType]);

  const shareDetails = async (client) => {
    let isOtpReset = true;
    const { response, code } = await sendLoginDetailsAPI(
      client?.uid,
      isOtpReset
    );
    if (code == 200) {
      if (client?.userType?.toLowerCase() == "sub_admin") {
        setShowSendLoginDetails({
          username: client?.username,
          password: response?.password,
          userType: "sub",
          otp: response?.otp,
        });
      }
      if (client?.userType?.toLowerCase() == "super_agent") {
        setShowSendLoginDetails({
          username: client?.username,
          password: response?.password,
          userType: "super",
          otp: response?.otp,
        });
      } else {
        setShowSendLoginDetails({
          username: client?.username,
          password: response?.password,
          userType: client?.userType?.toLowerCase()?.replace("_", " "),
          otp: response?.otp,
        });
      }
    } else {
      toast.error(response);
    }
  };

  const handleCasinoAndBetLock = async (
    userId,
    isCasino,
    isBet,
    type = "bet"
  ) => {
    if (type == "casino") {
      const { response, code, error, status } = await ChangeClientStatusAPI(
        userId,
        clientData.isActive,
        isBet,
        isCasino,
        "status"
      );
      if (code === 200) {
        toast.success(response?.status || "User Update Successfully");
      } else if (code === 500) {
        toast.error(response);
      } else {
        toast.error(
          response || "Something went wrong Please check all you field"
        );
      }
    } else {
      const { response, code, error, status } = await ChangeClientStatusAPI(
        userId,
        clientData.isActive,
        isBet,
        isCasino,
        "bet-lock"
      );
      if (code === 200) {
        toast.success(response?.status || "User Update Successfully");
      } else if (code === 500) {
        toast.error(response);
      } else {
        toast.error(
          response || "Something went wrong Please check all you field"
        );
      }
    }
    getClient();
  };
  return (
    <div
      style={{
        paddingTop: "80px",
        paddingBottom: "120px",
      }}
      _ngcontent-ng-c3705319800=""
      className="main_content"
    >
      <router-outlet _ngcontent-ng-c3705319800="" />

      {isDWModalOpen && (
        <DWModal
          clientData={selectedClientDW}
          setIsModalOpen={setIsDWModalOpen}
          isModalOpen={isDWModalOpen}
          handleSubmit={handleUpdateCommission}
        />
      )}
      <app-admin-details _nghost-ng-c3771564327="">
        {showSendLoginDetails && (
          <div
            style={{
              height: "auto",
              width: "50% !important",
              marginLeft: "25%",
            }}
          >
            <SendLoginDetails
              show={showSendLoginDetails}
              setShow={setShowSendLoginDetails}
            />{" "}
          </div>
        )}

        {/* <div _ngcontent-ng-c3771564327="" className="page_header">
          <h1 _ngcontent-ng-c3771564327="" className="page_heading">
            {userType} Details
          </h1>
          <div _ngcontent-ng-c3771564327="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3771564327="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3771564327="" className="breadcrumb">
                <li _ngcontent-ng-c3771564327="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3771564327="" href="#/dashboard">
                    Home
                  </a>
                </li>

                <li
                  _ngcontent-ng-c3771564327=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType?.toUpperCase()} Details
                </li>
              </ol>
            </nav>
          </div>
        </div> */}
        <div className="gx-bg-grey gx-px-5 gx-pt-3 gx-bg-flex">
          <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-pt-1 gx-text-capitalize">
            {userType?.toUpperCase()} Details
          </span>
          <button
            type="button"
            className="ant-btn ant-btn-primary"
            onClick={() => navigate(-1)}
          >
            <span>Back</span>
          </button>
        </div>

        <div
          _ngcontent-ng-c3771564327=""
          className="Super_master_details_section"
        >
          <div className="gx-px-3 gx-pt-3 gx-bg-flex gx-justify-content-around">
            <button type="button" className="ant-btn ant-btn-primary">
              <a href={`/create/new/${userType}`}>+ Create </a>
            </button>
            <button type="button" className="ant-btn ant-btn-primary">
              <a href={`/update_limit/${userType}`}>Update Limit </a>
            </button>
          </div>
          {sendLoginDetails && (
            <div className="ant-modal-root">
              <div className="ant-modal-mask" />
              <div tabIndex={-1} className="ant-modal-wrap">
                <div
                  role="dialog"
                  aria-labelledby="rc_unique_2"
                  aria-modal="true"
                  className="ant-modal gx-px-3"
                  style={{ width: 520, transformOrigin: "98.5px 194px" }}
                >
                  <div
                    tabIndex={0}
                    aria-hidden="true"
                    style={{
                      width: 0,
                      height: 0,
                      overflow: "hidden",
                      outline: "none",
                    }}
                  />
                  <div className="ant-modal-content">
                    <button
                      type="button"
                      aria-label="Close"
                      className="ant-modal-close"
                    >
                      <span className="ant-modal-close-x">
                        <span
                          role="img"
                          aria-label="close"
                          className="anticon anticon-close ant-modal-close-icon"
                        >
                          <svg
                            fillRule="evenodd"
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="close"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                          </svg>
                        </span>
                      </span>
                    </button>
                    <div className="ant-modal-header">
                      <div className="ant-modal-title" id="rc_unique_2">
                        Reset Password
                      </div>
                    </div>
                    <div className="ant-modal-body">
                      <textarea
                        rows={7}
                        readOnly=""
                        className="ant-input"
                        defaultValue={
                          "  New Password\n  LINK : tvspro99.com\n  ID : C18590\n  PW : M880IS\n  \n  "
                        }
                      />
                    </div>
                    <div className="ant-modal-footer">
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary gx-pointer"
                      >
                        <span>Save &amp; Copy</span>
                      </button>
                      <button
                        type="button"
                        className="ant-btn ant-btn-default gx-bg-grey gx-text-light-grey gx-pointer"
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-hidden="true"
                    style={{
                      width: 0,
                      height: 0,
                      overflow: "hidden",
                      outline: "none",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {userUnsettledBets && userUnsettledBets?.length > 0 && (
            <div className="ant-modal-root">
              <div className="ant-modal-mask" />
              <div tabIndex={-1} className="ant-modal-wrap">
                <div
                  role="dialog"
                  aria-labelledby="rc_unique_1"
                  aria-modal="true"
                  className="ant-modal gx-px-3"
                  style={{
                    width: 750,
                  }}
                >
                  <div
                    tabIndex={0}
                    aria-hidden="true"
                    style={{
                      width: 0,
                      height: 0,
                      overflow: "hidden",
                      outline: "none",
                    }}
                  />
                  <div className="ant-modal-content">
                    <button
                      type="button"
                      aria-label="Close"
                      className="ant-modal-close"
                      onClick={() => setUserUnsettledBets(null)}
                    >
                      <span className="ant-modal-close-x">
                        <span
                          role="img"
                          aria-label="close"
                          className="anticon anticon-close ant-modal-close-icon"
                        >
                          <svg
                            fillRule="evenodd"
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="close"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                          </svg>
                        </span>
                      </span>
                    </button>
                    <div className="ant-modal-header">
                      <div className="ant-modal-title" id="rc_unique_1">
                        {userUnsettledBets?.content?.[0]?.username} -{" "}
                        {userType} Bets
                      </div>
                    </div>
                    <div className="ant-modal-body">
                      <p>Bets</p>
                      <div className="ant-table-wrapper gx-table-responsive  login-table-header">
                        <div className="ant-spin-nested-loading">
                          <div className="ant-spin-container">
                            <div className="ant-table ant-table-small ant-table-bordered">
                              <div className="ant-table-container">
                                <div className="ant-table-content">
                                  <table
                                    style={{
                                      tableLayout: "auto",
                                    }}
                                  >
                                    <colgroup />
                                    <thead className="ant-table-thead">
                                      <tr>
                                        <th className="ant-table-cell">
                                          Event Name
                                        </th>
                                        <th className="ant-table-cell">
                                          Runner Name
                                        </th>
                                        <th className="ant-table-cell">
                                          Rate
                                        </th>
                                        <th className="ant-table-cell">
                                          Run
                                        </th>
                                        <th className="ant-table-cell">
                                          Type
                                        </th>
                                        <th className="ant-table-cell">
                                          Sports
                                        </th>
                                        <th className="ant-table-cell">
                                          Profit/Loss
                                        </th>
                                        <th className="ant-table-cell">
                                          Market Name
                                        </th>
                                        <th className="ant-table-cell">
                                          Place Date
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                      {userUnsettledBets?.map((bet) => (
                                        <tr
                                          data-row-key="6731b25054f4f6f226cdece4"
                                          className={`ant-table-row ant-table-row-level-0  ${
                                            bet?.lay_rate || bet?.lay_run
                                              ? "lay"
                                              : "back"
                                          }`}
                                        >
                                          <td className="ant-table-cell">
                                            {bet?.event_name}
                                          </td>
                                          <td className="ant-table-cell">
                                            {bet?.runner_name}
                                          </td>

                                          <td className="ant-table-cell">
                                            {bet?.back_rate || bet?.lay_rate
                                              ? bet?.back_rate ||
                                                bet?.lay_rate
                                              : bet?.rate}
                                          </td>
                                          <td className="ant-table-cell">
                                            {bet?.back_run || bet?.lay_run}
                                          </td>
                                          <td className="ant-table-cell">
                                            {bet?.lay_run || bet?.lay_run
                                              ? "Lay"
                                              : "Back"}
                                          </td>
                                          <td className="ant-table-cell">
                                            {bet?.event_type}
                                          </td>
                                          <td className="ant-table-cell">
                                            {bet?.profit
                                              ? bet?.profit
                                              : bet?.loss}
                                          </td>
                                          <td className="ant-table-cell">
                                            {bet?.marketName}
                                          </td>
                                          <td className="ant-table-cell">
                                            {moment(bet?.placeDate).format(
                                              "DD-MM-YYYY hh:mm A"
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-hidden="true"
                    style={{
                      width: 0,
                      height: 0,
                      overflow: "hidden",
                      outline: "none",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div _ngcontent-ng-c3771564327="" className="custom_table">
            {/**/}
            <div _ngcontent-ng-c3771564327="" className="table-responsive">
              <table _ngcontent-ng-c3771564327="" className="table">
                <thead _ngcontent-ng-c3771564327="">
                  <tr _ngcontent-ng-c3771564327="">
                    <th _ngcontent-ng-c3771564327="">SNo</th>
                    <th _ngcontent-ng-c3771564327="">Setting</th>
                    <th _ngcontent-ng-c3771564327="">Name</th>
                    <th _ngcontent-ng-c3771564327="">Parent Name</th>
                    <th _ngcontent-ng-c3771564327="">D.O.J.</th>
                    {userType?.toUpperCase() == "SUPER_ADMIN" && (
                      <th _ngcontent-ng-c3771564327="">Password</th>
                    )}
                    {userType?.toUpperCase() == "CLIENT" && (
                      <>
                        <th _ngcontent-ng-c3771564327="">Engaged Limit</th>
                        <th _ngcontent-ng-c3771564327="">Balance</th>
                      </>
                    )}
                    <th _ngcontent-ng-c3771564327="">Sport Share%</th>
                    <th _ngcontent-ng-c3771564327="">Type</th>
                    <th _ngcontent-ng-c3771564327="">Mat</th>
                    <th _ngcontent-ng-c3771564327="">Ses </th>
                    <th _ngcontent-ng-c3771564327="">Limit</th>
                    <th _ngcontent-ng-c3771564327="">User Lock</th>
                    {/* <th>Action</th> */}
                    <th _ngcontent-ng-c3771564327="">Bet</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c3771564327="">
                  {clientData?.childrenUserResponseDTOS?.map(
                    (client, index) => (
                      <tr _ngcontent-ng-c3771564327="">
                        <td _ngcontent-ng-c3771564327="">{index + 1}</td>
                        <td _ngcontent-ng-c3771564327="">
                          <div
                            _ngcontent-ng-c3771564327=""
                            className="dropdown action_drop"
                          >
                            <button
                              _ngcontent-ng-c3771564327=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              className="btn btn-primary dropdown-toggle plus_value"
                            >
                              {" "}
                            </button>
                            <ul
                              _ngcontent-ng-c3771564327=""
                              className="dropdown-menu"
                            >
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedClientDw({
                                      ...client,
                                      isDeposit: true,
                                    });
                                    setIsDWModalOpen(true);
                                  }}
                                >
                                  Deposit
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedClientDw({
                                      ...client,
                                    });
                                    setIsDWModalOpen(true);
                                  }}
                                >
                                  Withdraw
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(
                                      `/edit/${client.uid}/${userType}`,
                                      {
                                        state: {
                                          client,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  Edit
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(
                                      "/report/account_statement/" +
                                        client?.uid,
                                      {
                                        state: {
                                          ...client,
                                          userName: client.username,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  Statement
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => handleActiveInactive(client)}
                                >
                                  {client?.isActive ? "Active" : "Inactive"}
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() =>
                                    navigate(`/update/limit/${userType}`, {
                                      state: {
                                        client,
                                      },
                                    })
                                  }
                                >
                                  Client Limit
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  // data-bs-toggle="modal"
                                  data-bs-target="#detailaModal"
                                  className="dropdown-item"
                                  onClick={() => {
                                    shareDetails(client);
                                  }}
                                >
                                  Reset Password
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(`/report/login`, {
                                      state: client,
                                    });
                                  }}
                                >
                                  Login Report
                                </a>
                              </li>
                              {/*  <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  href={`/setting/change-password/${
                                    client?.uid
                                  }`}
                                >
                                  Change Password
                                </a>
                              </li> */}
                            </ul>
                          </div>
                        </td>
                        <td
                          onClick={() => {
                            navigate(
                              `/${client?.userType}/children/${
                                client?.username
                              }`
                            );
                          }}
                          className="gx-text-blue gx-pointer gx-text-nowrap"
                          _ngcontent-ng-c3771564327=""
                        >
                          {" "}
                          <i class="icon icon-view-o" /> {client?.name}
                          <br _ngcontent-ng-c3771564327="" /> (
                          {client?.username})
                        </td>
                        {/**/}
                        <td _ngcontent-ng-c3771564327="">
                          {" "}
                          {client?.parentUserName}{" "}
                          <br _ngcontent-ng-c3771564327="" /> (
                          {client?.parentUserId})
                        </td>

                        <td _ngcontent-ng-c3771564327="">
                          <span
                            _ngcontent-ng-c3771564327=""
                            style={{
                              width: 63,
                              display: "inline-block",
                            }}
                          >
                            {moment(client?.createdAt).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </span>
                        </td>
                        {userType?.toUpperCase() == "SUPER_ADMIN" && (
                          <td _ngcontent-ng-c3771564327="">
                            {client?.password}
                          </td>
                        )}
                        {userType?.toUpperCase() == "CLIENT" && (
                          <>
                            <td
                              style={{
                                color: "blue",
                                fontWeight: "bold",
                              }}
                              onClick={
                                () =>
                                  getUnsettledBetByUsername(
                                    client?.username,
                                    client?.name
                                  )
                                // setExposureTableModal(client)
                              }
                              _ngcontent-ng-c3771564327=""
                            >
                              {" "}
                              {Math.abs(client?.liability)}
                            </td>
                            <td>{parseFloat(client?.balance).toFixed(2)}</td>
                          </>
                        )}
                        <td _ngcontent-ng-c3771564327="">
                          {client.myPartnership}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          {client?.matchCommission == 0 ||
                          client?.sessionCommission == null
                            ? "NOC"
                            : "BYB"}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          {client?.matchCommission}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          {client?.sessionCommission}
                        </td>
                        <td
                          _ngcontent-ng-c3771564327=""
                          className="plus_value"
                        >
                          {parseFloat(
                            client.balance + client.liability
                          ).toFixed(2)}{" "}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          <div
                            _ngcontent-ng-c3771564327=""
                            className="form-check form-switch text-success"
                          >
                            <input
                              _ngcontent-ng-c3771564327=""
                              type="checkbox"
                              checked={client?.isActive}
                              role="switch"
                              onClick={() =>
                                /* The above code appears to be a comment block in
                              JavaScript. It mentions a function or method called
                              "handleActiveInactive" but the actual implementation of
                              this function is not provided in the comment block. */
                                handleActiveInactive(client)
                              }
                              id="flexSwitchCheckDefault4"
                              className="form-check-input"
                            />
                          </div>
                        </td>

                        {/*   <td _ngcontent-ng-c3771564327="">
                          <button
                            _ngcontent-ng-c3771564327=""
                            type="button"
                            className="btn btn-primary"
                            tabIndex={0}
                            onClick={() => {
                              navigate(`/report/account_statement/${client?.uid}
                              `);
                            }}
                          >
                            {" "}
                            Reports{" "}
                          </button>
                        </td> */}
                        <td _ngcontent-ng-c3771564327="">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <label>Bet Play</label>

                            <Switch
                              value={!client?.isBetLocked}
                              defaultChecked={!client?.isBetLocked}
                              checked={!client?.isBetLocked}
                              onChange={() => {
                                handleCasinoAndBetLock(
                                  client?.uid,
                                  false,
                                  !client?.isBetLocked
                                );
                              }}
                              color="primary"
                              type="checkbox"
                              style={{
                                marginRight: "5px",
                              }}
                            />

                            <label>Casino On</label>
                            <Switch
                              value={!client?.isCasinoLocked}
                              defaultChecked={!client?.isCasinoLocked}
                              checked={!client?.isCasinoLocked}
                              onChange={() => {
                                handleCasinoAndBetLock(
                                  client?.uid,
                                  !client?.isCasinoLocked,
                                  false,
                                  "casino"
                                );
                              }}
                              color="primary"
                              type="checkbox"
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}

                  {/**/}
                </tbody>
                {/**/}
              </table>
              <div _ngcontent-ng-c3771564327="" className="text-center">
                <span _ngcontent-ng-c3771564327="" colSpan={4}>
                  <pagination-controls
                    _ngcontent-ng-c3771564327=""
                    previouslabel="Previous"
                    nextlabel="Next"
                    screenreaderpaginationlabel="Pagination"
                    screenreaderpagelabel="page"
                    screenreadercurrentlabel="You're on page"
                  >
                    <pagination-template>
                      <nav role="navigation" aria-label="Pagination">
                        <ul className="ngx-pagination">
                          <li
                            onClick={() => {
                              if (currentPage > 0) {
                                setCurrentPage(currentPage - 1);
                              }
                            }}
                            className="pagination-previous disabled"
                          >
                            {/**/}
                            <span aria-disabled="true">
                              {" "}
                              Previous{" "}
                              <span className="show-for-sr">page</span>
                            </span>
                            {/**/}
                          </li>
                          {/**/}
                          <li className="small-screen">
                            {" "}
                            {1} /{" "}
                            {
                              clientData?.childrenUserResponseDTOSPage
                                ?.totalPages
                            }{" "}
                          </li>
                          <li className="current">
                            {/**/}
                            <span aria-live="polite">
                              <span className="show-for-sr">
                                You're on page{" "}
                              </span>
                              <span>{currentPage + 1}</span>
                            </span>
                            {/**/}
                            {/**/}
                          </li>

                          <li
                            onClick={() => {
                              if (currentPage < totalPages - 1) {
                                setCurrentPage(currentPage + 1);
                              }
                            }}
                            className="pagination-next"
                          >
                            <a tabIndex={0}>
                              {" "}
                              Next <span className="show-for-sr">page</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>
                          {/**/}
                        </ul>
                        {/**/}
                      </nav>
                    </pagination-template>
                  </pagination-controls>
                </span>
                {/**/}
              </div>
            </div>
          </div>
        </div>

        <div
          _ngcontent-ng-c3771564327=""
          id="detailaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div
            _ngcontent-ng-c3771564327=""
            className="modal-dialog modal-dialog-centered"
          >
            <div _ngcontent-ng-c3771564327="" className="modal-content">
              <div _ngcontent-ng-c3771564327="" className="modal-header">
                <h5
                  _ngcontent-ng-c3771564327=""
                  id="exampleModalLabel"
                  className="modal-title"
                >
                  User Deatils
                </h5>
                <button
                  _ngcontent-ng-c3771564327=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c3771564327="" className="modal-body">
                <div _ngcontent-ng-c3771564327="" className="bet-slip-box">
                  <div _ngcontent-ng-c3771564327="" className="bet-slip">
                    <div _ngcontent-ng-c3771564327="" className="bet-nation">
                      <span _ngcontent-ng-c3771564327="">UserName : </span>
                    </div>
                    <div
                      _ngcontent-ng-c3771564327=""
                      className="match-result"
                    />
                  </div>
                  <div _ngcontent-ng-c3771564327="" className="bet-slip">
                    <div _ngcontent-ng-c3771564327="" className="bet-nation">
                      <span _ngcontent-ng-c3771564327="">password :</span>
                    </div>
                    <div
                      _ngcontent-ng-c3771564327=""
                      className="match-result"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c3771564327=""
                    className="place-bet-btn mt-4 text-align"
                  >
                    <button
                      _ngcontent-ng-c3771564327=""
                      className="btn btn-primary btn-block"
                    >
                      <span _ngcontent-ng-c3771564327="">
                        Share on WhatsApp
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c3771564327=""
          id="ViewMoreModal"
          tabIndex={-1}
          aria-labelledby="ViewMoreModalLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div _ngcontent-ng-c3771564327="" className="modal-dialog">
            <div _ngcontent-ng-c3771564327="" className="modal-content">
              <div _ngcontent-ng-c3771564327="" className="modal-header">
                <h5 _ngcontent-ng-c3771564327="" className="modal-title">
                  Modal
                </h5>
                <button
                  _ngcontent-ng-c3771564327=""
                  type="button"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div
                _ngcontent-ng-c3771564327=""
                className="modal-body text-center"
              >
                <p _ngcontent-ng-c3771564327="" className="message_peragraph">
                  Do you want to Lock/Unlock this user?
                </p>
                <div
                  _ngcontent-ng-c3771564327=""
                  className="place-bet-btn mt-4 text-align"
                >
                  <button
                    _ngcontent-ng-c3771564327=""
                    data-bs-dismiss="modal"
                    className="btn btn-primary btn-danger btn-block me-2"
                  >
                    <span _ngcontent-ng-c3771564327="">Cancel</span>
                  </button>
                  <button
                    _ngcontent-ng-c3771564327=""
                    className="btn btn-primary btn-block"
                  >
                    <span _ngcontent-ng-c3771564327="">Ok</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c3771564327=""
          id="betLockModal"
          tabIndex={-1}
          aria-labelledby="betLockModalLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div _ngcontent-ng-c3771564327="" className="modal-dialog">
            <div _ngcontent-ng-c3771564327="" className="modal-content">
              <div _ngcontent-ng-c3771564327="" className="modal-header">
                <h5 _ngcontent-ng-c3771564327="" className="modal-title">
                  Modal
                </h5>
                <button
                  _ngcontent-ng-c3771564327=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div
                _ngcontent-ng-c3771564327=""
                className="modal-body text-center"
              >
                <p _ngcontent-ng-c3771564327="" className="message_peragraph">
                  Do you want to Lock/Unlock this Bet?
                </p>
                <div
                  _ngcontent-ng-c3771564327=""
                  className="place-bet-btn mt-4 text-align"
                >
                  <button
                    _ngcontent-ng-c3771564327=""
                    data-bs-dismiss="modal"
                    className="btn btn-primary btn-danger btn-block me-2"
                  >
                    <span _ngcontent-ng-c3771564327="">Cancel</span>
                  </button>
                  <button
                    _ngcontent-ng-c3771564327=""
                    className="btn btn-primary btn-block"
                  >
                    <span _ngcontent-ng-c3771564327="">Ok</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-admin-details>
      {/**/}
    </div>
  );
}
