import React, { useEffect, useState } from "react";
import {
  getCasinoDetailsAPI,
  getCasinoReportHistoryAPI,
} from "../../service/AuthService";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function CasinoDetails() {
  const [payload, setPayload] = useState({ page: 0, size: 20 });
  const [data, setData] = useState([]);
  const getCasinoDetails = async () => {
    const { response, code } = await getCasinoDetailsAPI(
      payload.page,
      payload.size
    );
    if (code == 200) {
      setData(response);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getCasinoDetails();
  }, [payload]);

  return (
    <>
      <div _ngcontent-ng-c3705319800="" className="main_content">
        <main className="ant-layout-content gx-layout-content gx-px-md-5  ">
          <div
            className="gx-main-content-wrapper"
            style={{ marginBottom: 100 }}
          >
            <div className="ant-row ant-row-center">
              <div className="ant-col ant-col-xs-24 ant-col-lg-24">
                <div className="ant-row ant-row-center">
                  <div className="ant-card ant-card-bordered gx-card gx-w-100">
                    <div className="ant-card-body">
                      <div className="gx-bg-grey gx-px-5 gx-pt-2 gx-bg-flex">
                        <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-pt-1 gx-text-capitalize">
                          Diamond Casino Details
                        </span>
                        <button
                          type="button"
                          className="ant-btn ant-btn-primary"
                          onClick={() => navigate(-1)}
                        >
                          <span>Back</span>
                        </button>
                      </div>
                      <div
                        className="ant-row gx-bg-flex gx-justify-content-start gx-align-items-center gx-px-4 gx-pt-1"
                        style={{ marginLeft: "-10px", marginRight: "-10px" }}
                      >
                        <div className="ant-picker ant-picker-range  gx-border-redius0">
                          <div className="ant-picker-input ant-picker-input-active">
                            <input
                              readOnly=""
                              placeholder="Start date"
                              size={12}
                              autoComplete="off"
                              defaultValue="2024-11-12"
                            />
                          </div>
                          <div className="ant-picker-range-separator">
                            <span
                              aria-label="to"
                              className="ant-picker-separator"
                            >
                              <span
                                role="img"
                                aria-label="swap-right"
                                className="anticon anticon-swap-right"
                              >
                                <svg
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="swap-right"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="ant-picker-input">
                            <input
                              readOnly=""
                              placeholder="End date"
                              size={12}
                              autoComplete="off"
                              defaultValue="2024-11-19"
                            />
                          </div>
                          <div
                            className="ant-picker-active-bar"
                            style={{
                              left: 0,
                              width: 117,
                              position: "absolute",
                            }}
                          />
                          <span className="ant-picker-suffix">
                            <span
                              role="img"
                              aria-label="calendar"
                              className="anticon anticon-calendar"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="calendar"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />
                              </svg>
                            </span>
                          </span>
                          <span className="ant-picker-clear">
                            <span
                              role="img"
                              aria-label="close-circle"
                              className="anticon anticon-close-circle"
                            >
                              <svg
                                fillRule="evenodd"
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="close-circle"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z" />
                              </svg>
                            </span>
                          </span>
                        </div>
                        <div
                          className="ant-col gx-px-2 gx-bg-flex gx-pt-3"
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                        >
                          <button
                            type="button"
                            className="ant-btn ant-btn-danger gx-border-redius0"
                          >
                            <span>Apply</span>
                          </button>
                          <button
                            type="button"
                            className="ant-btn ant-btn-primary gx-border-redius0"
                          >
                            <span>Today P/L</span>
                          </button>
                        </div>
                      </div>
                      {/* <div>
                        <div className="ant-table-wrapper gx-table-responsive">
                          <div className="ant-spin-nested-loading">
                            <div className="ant-spin-container">
                              <div className="ant-table ant-table-small ant-table-bordered ant-table-empty">
                                <div className="ant-table-container">
                                  <div className="ant-table-content">
                                    <table style={{ tableLayout: "auto" }}>
                                      <colgroup />
                                      <thead className="ant-table-thead">
                                        <tr>
                                          <th className="ant-table-cell">
                                            Game Id
                                          </th>
                                          <th className="ant-table-cell">
                                            Type
                                          </th>
                                          <th className="ant-table-cell">
                                            Exposer
                                          </th>
                                          <th className="ant-table-cell">
                                            P/L
                                          </th>
                                          <th className="ant-table-cell">
                                            Client P/L
                                          </th>
                                          <th className="ant-table-cell">
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="ant-table-tbody">
                                        <tr className="ant-table-placeholder">
                                          <td
                                            colSpan={6}
                                            className="ant-table-cell"
                                          >
                                            <div className="ant-empty ant-empty-normal">
                                              <div className="ant-empty-image">
                                                <svg
                                                  className="ant-empty-img-simple"
                                                  width={64}
                                                  height={41}
                                                  viewBox="0 0 64 41"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <g
                                                    transform="translate(0 1)"
                                                    fill="none"
                                                    fillRule="evenodd"
                                                  >
                                                    <ellipse
                                                      className="ant-empty-img-simple-ellipse"
                                                      cx={32}
                                                      cy={33}
                                                      rx={32}
                                                      ry={7}
                                                    />
                                                    <g
                                                      className="ant-empty-img-simple-g"
                                                      fillRule="nonzero"
                                                    >
                                                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                                      <path
                                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                        className="ant-empty-img-simple-path"
                                                      />
                                                    </g>
                                                  </g>
                                                </svg>
                                              </div>
                                              <div className="ant-empty-description">
                                                No data
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable no-footer"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="
                                          #
                                      : activate to sort column ascending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="SNo: activate to sort column ascending"
                                >
                                  SNo
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-sort="descending"
                                  aria-label="Date Time: activate to sort column ascending"
                                >
                                  Date Time
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Game Type: activate to sort column ascending"
                                >
                                  Game Type
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Plus Minus: activate to sort column ascending"
                                >
                                  Plus Minus
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item) => (
                                <tr className="odd">
                                  <td>
                                    <div
                                      style={{
                                        width: "min-content",
                                      }}
                                      align="center"
                                    >
                                      <a
                                        className="dropdown-item btn btn-primary"
                                        onClick={() =>
                                          navigate(
                                            `/casino/casino-select-plus-minus-report/${
                                              item?.dateId
                                            }`
                                          )
                                        }
                                      >
                                        Show Details
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <label>{item?.id}</label>
                                  </td>
                                  <td className="sorting_1">
                                    <span>{item?.dateId}</span>
                                  </td>
                                  <td>Casino Ledger</td>
                                  <td>
                                    {parseFloat(item?.profitLoss).toFixed(2)}
                                  </td>
                                </tr>
                              ))}

                              <tr className="even">
                                <th />
                                <th />
                                <th className="sorting_1" />
                                <th />
                                <th>
                                  {parseFloat(
                                    data.reduce((acc, item) => {
                                      return acc + item.profitLoss;
                                    }, 0)
                                  ).toFixed(2)}
                                </th>
                              </tr>
                            </tbody>
                            <tfoot />
                          </table>
                          <div
                            id="example1_processing"
                            className="dataTables_processing card"
                            style={{ display: "none" }}
                          >
                            Processing...
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="example1_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing{" "}
                            {data.length > 0
                              ? payload.page * payload.size + 1
                              : 0}{" "}
                            to {payload.page * payload.size + data.length} of{" "}
                            {data.length} entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example1_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="example1_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="example1"
                                  data-dt-idx={0}
                                  tabIndex={0}
                                  className="page-link"
                                  onClick={() => {
                                    if (payload.page > 0) {
                                      setPayload({
                                        ...payload,
                                        page: payload.page - 1,
                                      });
                                    }
                                  }}
                                >
                                  Previous
                                </a>
                              </li>
                              <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="example1"
                                  data-dt-idx={1}
                                  tabIndex={0}
                                  onClick={() => {
                                    setPayload({
                                      ...payload,
                                      page: payload.page,
                                    });
                                  }}
                                  className="page-link"
                                >
                                  {payload.page + 1}
                                </a>
                              </li>
                              <li
                                className="paginate_button page-item next disabled"
                                id="example1_next"
                              >
                                <a
                                  href="#"
                                  aria-controls="example1"
                                  data-dt-idx={2}
                                  tabIndex={0}
                                  onClick={() => {
                                    if (data.length == payload.size) {
                                      setPayload({
                                        ...payload,
                                        page: payload.page + 1,
                                      });
                                    }
                                  }}
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
