import { AXIOS_INSTANCE } from ".";
import {
  BASE_URL,
  CASINO_SERVICE_URL,
  CRICKET_SERVICE_URL,
  getToken,
} from "../utils/constants";

export const loginAPI = async (username, password) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${BASE_URL}/auth/signin`, {
      username,
      password,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getDataReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/data-report`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const registerAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/signup`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/password`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updateCommissionAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${payload.id}/limit-update`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const checkValidEmailAPI = async (email) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/email/${email}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const checkValidUsernameAPI = async (username) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/username/${username}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserByIdAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/user/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getButtonValuesAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/client-button2`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const changeButtonValuesAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/user/update-client-button2`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const betHistoryAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/bet-history`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const unSettledBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/unsettled-bets`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const profitLossReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/profit-loss-report`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const currentBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/current-bets`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getActivityLogAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/activity-log`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getExposureTableAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/exposure-table`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const searchAPI = async (search) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/home-page/search`,
      {
        params: {
          search,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const scoreAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `http://marketsarket.in:3002/scoreurl/${eventId}`
    );
    return data?.score;
  } catch (error) {
    return error;
  }
};

export const getMatchScoreAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/score-sport`,
      {},
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const upcomingFixtureAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/upcoming-fixture`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const dashboardBannerAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/dashboard-banner`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAnnouncementAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/announcement`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetsFromAccountLogs = async (id) => {
  console.log(id);
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/bets-from-log`,
      {
        params: {
          accountLogId: id,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getLiveStreamingUrlAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/streaming/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserDataAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/accmanage/user/${userId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetHistoryAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/bet-history`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const searchUserAPI = async (user) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/admin/search-user`, {
      params: {
        search: user,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const generalReportAPI = async (type) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/general-report`,
      {
        params: {
          type,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllMatchesAPI = async (page, isSettled = false) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/all-sports?page=${page}&isSettled=${isSettled}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCoinHistoryAPI = async (id, startDate, endDate) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/children/${id}/coin-history`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getProfitLossReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/profit-loss-report`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCollectionReportAPI = async (
  startDate,
  endDate,
  userType,
  userName,
  isOld = false
) => {
  try {
    let params = {
      startDate: new Date(startDate).getTime(),
      endDate: new Date(endDate).getTime(),
      userType: userType?.toLocaleUpperCase(),
      userName,
    };
    if (userType) {
      delete params.userName;
    }
    if (userName) {
      delete params.userType;
    }
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/collection-report?isOld=${isOld}`,
      {
        params: params,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const accountLogAPI = async (payload, isOld = false) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/account-log?isOld=${isOld}`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const editClientAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${payload.id}/edit`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSportsScoreAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/score-sport-v2/${id}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updateUserStatusAPI = async (id, isActive, isBetLocked) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${id}/status`,
      {},
      {
        params: { isActive, isBetLocked },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getChildrenByEventId = async (id, forClient = false) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/children-by-event`,
      {
        params: {
          eventId: id,
          forClient,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchAndSessionBetsAPI = async (clientId, eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/client-bets/${clientId}/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getPlusMinusReportAPI = async (eventId, payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/${eventId}/plus-minus-report`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserActivityAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/activity-log`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUsersByTypeAPI = async (type) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/${type}/users`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getSecureCodeByChildIdAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/children/${id}/secure-code`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const bulkActiveDeactiveAPI = async (active, userType, userIds) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      userType
        ? `${BASE_URL}/admin/all-active-deactive?active=${active}&userType=${userType}`
        : `${BASE_URL}/admin/all-active-deactive?active=${active}`,
      {
        ids: userIds,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const sendLoginDetailsAPI = async (childId, isOtpReset = false) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/children/${childId}/send-login-details?isOtpReset=${isOtpReset}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetsFromAccountLogsAdminAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/bets-from-log`,
      {
        params: {
          accountLogId: id,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCommissionReportAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/commission-report`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCommissionReportByUserAPI = async (userId, params) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/commission-report/${userId}`,
      {
        params: params,
      }
    );
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};
export const getCommissionHistoryByUserAPI = async (params) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/commission-history/${params.userId}`,
      {
        params: params,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const resetCommissionReportByUserAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/commission-report/${userId}/reset`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBookMakerPositionAPI = async (
  eventId,
  userId,
  sport = "CRICKET"
) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/bookmaker-position`,
      {
        params: {
          eventId,
          sport,
          userId,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const getCasinoDetailsAPI = async (page, size) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/admin/casino-details`,
      {
        params: {
          page,
          size,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoReportHistoryAPI = async (page, size) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/casino-report-history`,
      {
        params: {
          page,
          size,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoClientBets = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/admin/user/client-bets`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoOddsAPI = async (game) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${CASINO_SERVICE_URL}/odds`, {
      params: {
        casinoGames: game,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllClientsCasinoBetsAPI = async (
  betStatus = "MATCHED",
  casinoGames
) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/admin/client-bets`,
      {
        params: {
          casinoGames,
          betStatus,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updateClientsCasinoBetsAPI = async (betId, betStatus) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CASINO_SERVICE_URL}/admin/client-bets/${betId}/status`,
      {},
      {
        params: {
          betId,
          betStatus,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const bulkDeleteGroupAPI = async (betIds = [], pwd = "") => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_SERVICE_URL}/cricket/admin/client-bets/${betIds}/group-delete?pwd=${pwd}`,
      {}
    );
    return data;
  } catch (error) {
    return error;
  }
};